import { PropsWithChildren, useEffect, useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentType, useGetBordersQuery } from '../../../generated/graphql';
import useAuth from '../../hooks/useAuth';
import DBContext from './DBContext';
import checkMobile from '../../../utils/checkMobile';
import DataLoading from './DataLoading';
import { db } from '../../../db/db';
import useLoadDB from '../../../db/loadDB';

const countOfContentTypes = Object.keys(ContentType).length;

const DBProvider = ({ children }: PropsWithChildren<any>) => {
  const { token, debugUseDb } = useAuth();
  const { data } = useGetBordersQuery({ variables: { withGeo: false }, skip: !token });
  const borders = data?.bordersByToken;
  const isMobile = checkMobile();
  const [loading, setLoading] = useState<Record<string, { total: number; current: number }>>({});
  const [showLoader, setShowLoader] = useState(false);
  const { t } = useTranslation();
  const { updateDB } = useLoadDB(setShowLoader, setLoading);

  useEffect(() => {
    if (borders) {
      const bordersConfig: any = {};
      borders.forEach((border) => {
        bordersConfig[border?.name as string] = {
          total: countOfContentTypes,
          current: countOfContentTypes,
        };
      });
      setLoading(bordersConfig);
      updateDB(borders);
    }
  }, [borders]);

  return (
    <DBContext.Provider value={{ canUseDb: debugUseDb ? false : !isMobile }}>
      <Button onClick={() => db.delete()} className="d-none" id="db-delete">
        {t('objects.updateObjectsData')}
      </Button>
      {showLoader && <DataLoading loading={loading} onClose={(): void => setShowLoader(false)} />}
      {children}
    </DBContext.Provider>
  );
};
export default DBProvider;
