import { getGeoFromServer } from './services/geoService';
import { ContentType } from '../generated/graphql';
import Observable from '../utils/Observable';
import addToDb from './addToDb';

const pageSize = 500;

class GeoData {
  totalPagesPlot!: number;

  currPagePlot: number = this.totalPagesPlot || 0;

  totalPagesOks!: number;

  currPageOks: number = this.totalPagesOks || 0;

  borderId!: number;

  loading = new Observable({
    page: 0,
    border: this.borderId,
    total: this.totalPagesPlot + this.totalPagesOks,
  });

  constructor({ borderId }: { borderId: number }) {
    this.borderId = borderId;
  }

  async init(): Promise<void> {
    const initVariables = (contentType: ContentType) => ({
      workBorderId: this.borderId,
      contentType,
      pas: {
        pagination: {
          page: 0,
          size: pageSize,
        },
      },
    });

    const reqPlot = await getGeoFromServer(initVariables(ContentType.Plot));

    const reqOks = await getGeoFromServer(initVariables(ContentType.Oks));

    addToDb(reqPlot.data.content);
    addToDb(reqOks.data.content);

    this.totalPagesPlot = reqPlot.data.totalPages;
    this.totalPagesOks = reqOks.data.totalPages;

    this.loading.set({
      page: reqPlot.data.totalPages + reqOks.data.totalPages,
      border: this.borderId,
      total: this.totalPagesPlot + this.totalPagesOks,
    });
  }

  async getPageData(pagePlot: number, pageOks: number): Promise<void> {
    if (pagePlot <= 0 && pageOks <= 0) return Promise.resolve();

    const variables = (page: number, contentType: ContentType) => ({
      workBorderId: this.borderId,
      contentType,
      pas: {
        pagination: { page, size: pageSize },
      },
    });

    if (pagePlot > 0 || (this.totalPagesPlot === 1 && pagePlot === 0)) {
      const { data } = await getGeoFromServer(variables(pagePlot, ContentType.Plot));
      addToDb(data.content);
      this.currPagePlot = data.number;
    }

    if (pageOks > 0 || (this.totalPagesPlot === 1 && pageOks === 0)) {
      const { data } = await getGeoFromServer(variables(pageOks, ContentType.Oks));
      addToDb(data.content);
      this.currPageOks = data.number;
    }

    this.loading.set({
      page: this.currPagePlot + this.currPageOks,
      border: this.borderId,
      total: this.totalPagesPlot + this.totalPagesOks,
    });

    return this.getPageData(
      pagePlot > 0 ? pagePlot - 1 : pagePlot,
      pageOks > 0 ? pageOks - 1 : pageOks
    );
  }
}
export default GeoData;
