import { Roles } from '../../generated/graphql';
import useAuth from './useAuth';

const useRoles = () => {
  const { user } = useAuth();
  const userRoles: Roles[] | null = user ? user?.roles.map((role) => role.roleName) : null;
  const isDEVELOPER = () => userRoles?.some((role) => role === Roles.Developer) ?? false;
  // eslint-disable-next-line camelcase
  const isADMIN_USER = (inherit = true) =>
    userRoles?.some((role) => role === Roles.AdminUser || isDEVELOPER()) ?? false;
  // eslint-disable-next-line camelcase
  const isANT_MANAGER = (inherit = true) =>
    userRoles?.some((role) => role === Roles.AntManager || (inherit && isADMIN_USER())) ?? false;
  // eslint-disable-next-line camelcase
  const isANT_OFFICER = (inherit = true) =>
    userRoles?.some((role) => role === Roles.AntOfficer || (inherit && isANT_MANAGER())) ?? false;
  const isVIEWER = (inherit = true) =>
    userRoles?.some((role) => role === Roles.Viewer || (inherit && isANT_OFFICER())) ?? false;
  // eslint-disable-next-line camelcase
  const isBACK_OFFICE = (inherit = true) =>
    userRoles?.some((role) => role === Roles.BackOffice || (inherit && isVIEWER())) ?? false;
  const isANT = (inherit = true) =>
    userRoles?.some((role) => role === Roles.Ant || (inherit && isBACK_OFFICE())) ?? false;
  // eslint-disable-next-line camelcase
  const isSTANDARD_USER = (inherit = true) =>
    userRoles?.some((role) => role === Roles.StandardUser || (inherit && isADMIN_USER())) ?? false;
  const isREGISTRY = (inherit = true) =>
    userRoles?.some((role) => role === Roles.Registry || (inherit && isADMIN_USER())) ?? false;

  return {
    isDEVELOPER,
    isADMIN_USER,
    isANT_MANAGER,
    isANT_OFFICER,
    isVIEWER,
    isBACK_OFFICE,
    isANT,
    isSTANDARD_USER,
    isREGISTRY,
  };
};
export default useRoles;
