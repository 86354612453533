import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Views } from '../../ui/core/Analytics/Views/models/Views';
import { TableViews } from '../../ui/core/Analytics/Views/models/TableViews';
import { AnalyticsViews } from '../../ui/core/Analytics/Views/models/AnalyticsViews';

export interface AnalyticsState {
  activeViews: Views;
  tableView: TableViews;
  analyticsView: AnalyticsViews;
  selectedObject: any;
  filters: any;
}

const initialAnalyticsState: AnalyticsState = {
  activeViews: 'table',
  tableView: 'antsSalaries',
  analyticsView: 'regions',
  selectedObject: null,
  filters: {},
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: initialAnalyticsState,
  reducers: {
    changeMainAnalyticsView(draftState, action: PayloadAction<Views>) {
      draftState.activeViews = action.payload;
    },
    handleChangeTableView(draftState, action: PayloadAction<TableViews>) {
      draftState.tableView = action.payload;
    },
    handleChangeAnalyticsView(draftState, action: PayloadAction<AnalyticsViews>) {
      draftState.analyticsView = action.payload;
    },
    selectObject(draftState, action: PayloadAction<any>) {
      draftState.selectedObject = action.payload;
    },
    handleFiltersChange(
      draftState,
      action: PayloadAction<{ filterName: AnalyticsViews | TableViews; dto: any }>
    ) {
      draftState.filters[action.payload.filterName] = action.payload.dto;
    },
  },
});

export const { actions } = analyticsSlice;
export default analyticsSlice.reducer;
