import React from 'react';
import { Provider } from 'react-redux';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { YMInitializer } from 'react-yandex-metrika';
import App from './ui/core/App';
import AuthContext from './ui/contexts/AuthContext';
import store from './store/index';
import getUser from './utils/userCoockies';
import resources from './locales/index';

const init = async (): Promise<JSX.Element> => {
  const i18n = i18next.createInstance();
  await i18n.use(initReactI18next).init({ resources, fallbackLng: 'ru' });
  const user = getUser();

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <AuthContext.Provider value={user}>
          <YMInitializer
            accounts={[91449137]}
            version="2"
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true,
            }}
          />
          <App />
        </AuthContext.Provider>
      </I18nextProvider>
    </Provider>
  );
};

export default init;
