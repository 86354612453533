import { EntityType } from '../../../generated/graphql';

export const EntitySubtypes: {
  [key: string]: {
    value: string;
    label: string;
    fullLabel: string;
    index: number;
  };
} = {
  HOMEOWNERSHIP: {
    value: 'HOMEOWNERSHIP',
    label: 'ДХ',
    fullLabel: 'Домохозяйства',
    index: 0,
  },
  PLOT: {
    value: EntityType.Plot,
    label: 'ЗУ',
    fullLabel: 'Земельные участки',
    index: 1,
  },
  OKS: {
    value: EntityType.Oks,
    label: 'ОКС',
    fullLabel: 'Объекты капитального строительства',
    index: 2,
  },
  ROOM: { value: EntityType.Room, label: 'Пом', fullLabel: 'Помещение', index: 3 },
  CHECKPOINT: {
    value: 'CHECKPOINT',
    label: 'ПП',
    fullLabel: 'Пункты пропуска',
    index: 3,
  },
  FILE_INFO: {
    value: 'FILE_INFO',
    label: 'ФИ',
    fullLabel: 'Информация о выписке КПТ',
    index: 4,
  },
  KPT_SET: {
    value: 'KPT_SET',
    label: 'КПТ',
    fullLabel: 'Информация о наборе',
    index: 5,
  },
};
