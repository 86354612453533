import { PlotUsage } from '../../../../../../generated/graphql';

const plotUsages: { [key in PlotUsage]: string } = {
  [PlotUsage.Apartment]: 'Под МКД',
  [PlotUsage.Catering]: 'Под объект общепита',
  [PlotUsage.CommercialObject]: 'Под коммерчекий объект',
  [PlotUsage.Communication]: 'Под объект связи',
  [PlotUsage.CulturalObject]: 'Под историко-культурный объект',
  [PlotUsage.DefenceAndSecurity]: 'Под оборону и безопастность',
  [PlotUsage.Education]: 'Под образовательную деятельность',
  [PlotUsage.Farming]: 'Под лпх',
  [PlotUsage.GeneralPurpose]: 'Для общего пользования',
  [PlotUsage.HealthObject]: 'Под объект здравоохранения',
  [PlotUsage.Ilb]: 'Под ИЖС',
  [PlotUsage.Industrial]: 'Под объект промышленности',
  [PlotUsage.Other]: 'Иное',
  [PlotUsage.Power]: 'Под объект энергетики',
  [PlotUsage.PublicObject]: 'Под общественное здание',
  [PlotUsage.Religion]: 'Под религиозный объект',
  [PlotUsage.RoadServiceObject]: 'Под объект дорожного сервиса',
  [PlotUsage.Shop]: 'Под магазин',
  [PlotUsage.Storage]: 'Под склад',
  [PlotUsage.Trading]: 'Под объект торговли',
  [PlotUsage.Transport]: 'Под объект транспорта',
  [PlotUsage.Undefined]: 'Не определено',
};
export default plotUsages;
