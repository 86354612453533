import { View } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { RootState } from '../index';
import { MapState } from './mapSlice';
import { MenuStates } from '../../ui/shared/models/MenuStates';
import { MapCondition } from '../../ui/core/Map/shared/models/MapCondition';

const getMapState = (state: RootState): MapState => state.mapState;

const getMapTile = (state: RootState): any => state.mapState.tileLayer;

const getMapView = (state: RootState): View => state.mapState.view;

const handleFeaturesFlag = (state: RootState): boolean => state.mapState.featuresFlag;

const getCurrZoom = (state: RootState): number => state.mapState.currZoom;

const getIsMobile = (state: RootState): boolean => state.mapState.isMobile;

const getDebugShow = (state: RootState): boolean => state.mapState.showDebug;

const getGeoPosition = (state: RootState): Coordinate | null => state.mapState.geoPosition;

const getMenuState = (state: RootState): MenuStates => state.mapState.menuState;

const getMapCondition = (state: RootState): MapCondition => state.mapState.mapCondition;

export {
  getMapState,
  getMapTile,
  getMapView,
  handleFeaturesFlag,
  getCurrZoom,
  getIsMobile,
  getDebugShow,
  getGeoPosition,
  getMenuState,
  getMapCondition,
};
