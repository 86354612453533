import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CustomSelect from '../../../../../../../shared/components/CustomSelect/CustomSelect';
import plotUsages from '../../../../../../Forms/components/usage/helpers/plotUsages';
import oksUsages from '../../../../../../Forms/components/usage/helpers/oksUsages';
import './usageFilterStyles.scss';
import createOptions from '../../../../helpers/createOptions';
import findUsage from '../../../../helpers/finsdUsage';
import { selectors } from '../../../../../../../../store';
import { EntitySubtypes } from '../../../../../../../shared/models/EntitySubtypes';
import { labelType } from '../../../../types/TMainDataIndex';

const UsageFilter = (props: any) => {
  const { field, update, index } = props;
  const { t } = useTranslation();
  const { tableType } = useSelector(selectors.getModalsInfo).modal;

  const getOptions = (objType: string): labelType => {
    switch (objType) {
      case EntitySubtypes.HOMEOWNERSHIP.value:
        return { ...plotUsages, ...oksUsages };
      case EntitySubtypes.OKS.value:
        return { ...oksUsages };
      default:
        return { ...plotUsages, ...oksUsages };
    }
  };

  const options = createOptions(getOptions(tableType));

  return (
    <CustomSelect
      options={options}
      className="customSelect"
      isSearchable
      isClearable
      placeholder={t('forms.objectForm.stepperForm.selectUsage')}
      onChange={(selected: any): void => {
        const changedField = field;
        changedField.value = selected?.value;
        update(index, changedField);
      }}
      menuPortalTarget={document.body}
      defaultValue={findUsage(field?.value, options)}
      value={findUsage(field?.value, options)}
    />
  );
};

export default UsageFilter;
