import { Modal } from 'react-bootstrap';
import Select, { ActionMeta } from 'react-select';
import { useTranslation } from 'react-i18next';
import { ReactElement, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  GetHoByIdDocument,
  GetOksByIdsDocument,
  ProcessingStatusValue,
  useUpdateProcessStatusMutation,
} from '../../../../generated/graphql';
import { ModalProps } from '../interfaces/ModalProps';
import getProcessStatusObject from '../../../shared/helpers/getProcessStatusObject';
import { getAdminRoleName, getViewerRoleName } from '../../../shared/helpers/getRoles';
import useRoles from '../../../hooks/useRoles';

const ProcessStatusModal = (props: ModalProps): ReactElement => {
  const { data } = props;
  const { t } = useTranslation();
  const [updateStatus] = useUpdateProcessStatusMutation();
  const [loading, setLoading] = useState(false);
  const apollo = useApolloClient();
  const { isADMIN_USER: isAdmin, isVIEWER: isViewer } = useRoles();
  const getRoleName = getAdminRoleName(isAdmin(false)) || getViewerRoleName(isViewer(false));
  const [selected, setSelected] = useState(
    data.processStatusObj
      ? getProcessStatusObject(getRoleName)[data.processStatusObj.status as ProcessingStatusValue]
      : null
  );

  const onChange = async (option: any, actionMeta: ActionMeta<any>): Promise<void> => {
    setLoading(true);
    setSelected(option);
    const { data: updated } = await updateStatus({
      variables: {
        dto: {
          objectId: data.id,
          contentType: data.contentType,
          status: actionMeta.action === 'clear' ? ProcessingStatusValue.None : option?.value,
        },
      },
    });
    if (updated?.updateProcessingStatus) {
      setLoading(false);
      apollo.refetchQueries({ include: [GetHoByIdDocument, GetOksByIdsDocument] });
    }
  };

  const processStatusObjectOptions = Object.values(getProcessStatusObject(getRoleName)).sort(
    (a: any, b: any) => a.order - b.order
  );

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('backOffice.modals.processStatus')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Select
          isSearchable
          isClearable
          isDisabled={loading}
          placeholder={t('backOffice.modals.placeholderProcessStatus')}
          options={processStatusObjectOptions}
          onChange={onChange}
          value={selected}
        />
      </Modal.Body>
    </>
  );
};
export default ProcessStatusModal;
