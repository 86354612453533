import { Component } from 'react';
import ErrorPage from './ErrorBoundaryView';

interface Props {
  children: React.ReactNode;
}

class ErrorBoundary extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: '',
      error: '',
    };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error, errorInfo });
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ErrorPage />;
    }
    return children;
  }
}

export default ErrorBoundary;
