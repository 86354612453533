import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import { ModalProps } from '../interfaces/ModalProps';

const TestModal: FC<ModalProps> = ({ onHide }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header>
        <Modal.Title>Stormbringer</Modal.Title>
        <Button
          type="button"
          className="d-flex align-items-center justify-content-center"
          onClick={onHide}
        >
          ×
        </Button>
      </Modal.Header>
      <Modal.Body>Powered by {t('dtechs')}</Modal.Body>
    </>
  );
};

export default TestModal;
