import axios from 'axios';
import { GetGeoInput, GetGeoOutput, GetLasUpdateInput } from '../types';
import getEnvs from '../../environments/env';
import { getGeoPath } from '../constants';

const { api } = getEnvs();

const getGeoFromServer = (variables: GetGeoInput) => {
  return axios.post<GetGeoOutput>([api, getGeoPath].join('/'), variables);
};

const getLasUpdateDate = (variables: GetLasUpdateInput) => {
  console.log(variables);
  return Promise.resolve();
};

export { getGeoFromServer, getLasUpdateDate };
