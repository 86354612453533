import { makeUniqueId } from '@apollo/client/utilities';
import axios from 'axios';
import { useState } from 'react';
import { BiErrorCircle } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions } from '../../../../store';

const useInterceptorsReq = (): any => {
  const currentUser = localStorage.getItem('userData')
    ? JSON.parse(localStorage.getItem('userData') as string)
    : null;
  const [user, setUser] = useState(currentUser ? { ...currentUser } : null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const errorHandler = async (error: any): Promise<void> => {
    let err;
    if (typeof error?.message === 'string') {
      err = error;
    } else {
      err = await error?.text().then((res: any): any => {
        const obj = JSON.parse(res);
        return obj;
      });
    }

    dispatch(
      actions.addNotification({
        status: 'error',
        body: err?.message,
        statusIcon: BiErrorCircle,
        delay: 5000,
        id: makeUniqueId('err-'),
      })
    );
  };

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error(error);
      dispatch(
        actions.addNotification({
          status: 'error',
          body: error.body || t('general.errors.connectionError'),
          header: t('general.errors.error'),
          statusIcon: BiErrorCircle,
          delay: 5000,
          id: 'err-network',
        })
      );
    }
  );

  axios.interceptors.request.use(
    function (config) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('userData') as string)?.jwt}`,
        },
      };
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response?.use(
    function (response) {
      return response;
    },
    async function (error) {
      console.log(error);
      if (error?.status === '500') return Promise.reject(error);
      await errorHandler(error?.response?.data);
      return Promise.reject(error);
    }
  );

  return { user, setUser, currentUser };
};

export default useInterceptorsReq;
