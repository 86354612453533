import { ReactElement, useEffect, useState } from 'react';
import { Card } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';
import { FaRegHandPointer } from 'react-icons/fa';
import { deleteFilters, getFiltersForRegistry } from '../SmartFilter/services/filter-services';

const AllSavedFiltersListView = ({
  data: object,
  onHide,
}: {
  data: any;
  onHide: any;
}): ReactElement => {
  const { append, resetFilters, registryId } = object;

  const [savedFilters, setSavedFilters] = useState<any>([]);

  const getFilters = async (): Promise<any> => {
    const { data } = await getFiltersForRegistry(registryId);
    if (data) setSavedFilters(data);
    return data;
  };

  const deleteFiltersFromModal = async (id: any): Promise<void> => {
    const { data } = await deleteFilters(id);
  };

  const onClick = (filter: any): void => {
    resetFilters();
    filter.searchCriteriaList.map((f: { filterKey: string; operation: string; value: string }) =>
      append({ field: f.filterKey, operator: f.operation, value: f.value })
    );
    onHide();
  };

  const renderFilters = (): ReactElement => {
    return savedFilters?.map((filter: any, index: number) => (
      <Card
        size="small"
        title={`${index + 1}. ${filter?.filterName}`}
        className="my-3 p-2 px-3 d-flex flex-column justify-content-between"
        style={{ marginLeft: 5, width: 300, height: 200 }}
        actions={[
          <AiOutlineClose
            onClick={() => {
              setSavedFilters(savedFilters.filter((el: any) => el.id !== filter.id));
              deleteFiltersFromModal(filter.id);
            }}
          />,
          <FaRegHandPointer onClick={(): void => onClick(filter?.dto)} />,
        ]}
      >
        <span>{filter?.description || ''}</span>
      </Card>
    ));
  };

  useEffect(() => {
    getFilters();
  }, []);

  return (
    <div className="overflow-auto d-flex flex-wrap justify-content-center" style={{ height: 500 }}>
      {renderFilters()}
    </div>
  );
};

export default AllSavedFiltersListView;
