import { RootState } from '../index';
import { TaskObject, TaskObjectStatus } from '../../generated/graphql';
import { TasksState } from './tasksSlice';

const getTasksState = (state: RootState): TasksState => state.tasksState;

const getTaskObjects = (state: RootState): TaskObject[] => state.tasksState.objects;

const getTaskId = (state: RootState): string | null => state.tasksState.currTaskId;

const getNewObjStatus = (state: RootState): { objId: string; status: TaskObjectStatus } | null =>
  state.tasksState.newObjStatus;

export { getTasksState, getTaskObjects, getNewObjStatus, getTaskId };
