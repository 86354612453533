const usePortal = (
  containerName?: string,
  parentElement: string | null = null
): { element: HTMLElement; removePortal: () => void } => {
  if (containerName) {
    const portalId = containerName;
    let element = document.getElementById(portalId);
    const getElement = !parentElement ? document.body : document.getElementById(parentElement);
    const removePortal = () => {
      getElement?.removeChild(element as HTMLElement);
    };

    if (element) {
      return { element, removePortal };
    }

    element = document.createElement('div');
    element.setAttribute('id', portalId);

    getElement?.appendChild(element);

    return { element, removePortal };
  }

  return { element: document.body, removePortal: () => null };
};
export default usePortal;
