import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import useAuth from '../../../../hooks/useAuth';
import routes from '../../../../pages/routes';
import { envVars } from '../../../../../environments/env';

const PrivateRoute = (): React.ReactElement => {
  const auth = useAuth();

  return auth.user || envVars.REACT_APP_CREDENTIALS ? (
    <Outlet />
  ) : (
    <Navigate to={routes.logInPagePath()} />
  );
};

export default PrivateRoute;
