import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HintField } from '../../../generated/graphql';

export interface ActionsState {
  showLabels: boolean;
  labelsField: HintField;
}

const initialActionsState: ActionsState = {
  showLabels: false,
  labelsField: HintField.Address,
};

const actionsSlice = createSlice({
  name: 'actions',
  initialState: initialActionsState,
  reducers: {
    changeLabelsVisibility(draftState, action: PayloadAction<boolean>) {
      draftState.showLabels = action.payload;
    },
    changeLabelsField(draftState, action: PayloadAction<HintField>) {
      draftState.labelsField = action.payload;
    },
  },
});

export const { actions } = actionsSlice;
export default actionsSlice.reducer;
