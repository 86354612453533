import { ArrayHelpers } from 'formik';
import { ActionMeta } from 'react-select';
import dayjs from 'dayjs';
import { FilterKinds } from './FilterTypes';
import { FieldType } from '../../../../../../generated/graphql';

const useFilterController = (
  onChange: (obj: any) => void,
  addressChange: (obj: any) => void,
  regionChange: (obj: any) => void,
  onFieldFiltersChange: (obj: any) => void
) => {
  const onSubmit = (values: any): void => {
    const filters = values
      .filter(
        (field: { value: any; kind: FilterKinds }) =>
          field.value && field.kind !== 'ADDRESS' && Object.keys(FilterKinds).includes(field.kind)
      )
      .map(
        (field: {
          kind: any;
          value: any;
          dateFrom: number | Date;
          dateTo: number | Date;
          id: string;
        }) => {
          return {
            id: undefined,
            kind: field.kind,
            value: field.value,
            dateFrom: field.dateFrom ? dayjs(field.dateFrom).toISOString() : undefined,
            dateTo: field.dateTo ? dayjs(field.dateTo).toISOString() : undefined,
          };
        }
      );
    onChange(filters);

    const addr = values.find((field: any) => field.kind === 'ADDRESS');
    addressChange(addr || null);
    regionChange(values.find((field: any) => field.kind === 'REGION') || []);

    const fieldFilters = values.filter(
      (field: { kind: string; value: string; emptyField: boolean | null }) =>
        (field?.value || field?.emptyField !== null) &&
        Object.values(FieldType).includes(field.kind as FieldType)
    );
    onFieldFiltersChange(
      fieldFilters.map((field: any) => ({
        field: field.kind,
        value: field?.value || null,
        emptyField: field?.value ? null : !!field?.emptyField,
      }))
    );
  };

  const onSelectFilter = (helpers: ArrayHelpers, filter: any, meta: ActionMeta<any>): void => {
    if (filter) {
      helpers.push({ kind: filter });
    }
  };

  return { onSubmit, onSelectFilter };
};
export default useFilterController;
