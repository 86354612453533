import { BiAnalyse, BiRadar } from 'react-icons/bi';
import './Loader.scss';
import { Color } from 'react-bootstrap/types';
import { FiCompass } from 'react-icons/fi';
import { Sizes } from '../../models/Sizes';

type LoaderVariants = 'radar' | 'planets' | 'compass';

type LoaderProps = {
  size?: Sizes;
  color?: Color;
  className?: string;
  style?: any;
  variant?: LoaderVariants;
};

const defaultProps = {
  size: 'inherit',
  color: 'primary',
  className: '',
  style: {},
  variant: 'planets',
} as LoaderProps;

const loaderIconMap = {
  radar: BiRadar,
  planets: BiAnalyse,
  compass: FiCompass,
};

const Loader = ({ size, color, className, style, variant }: LoaderProps) => {
  const Icon = variant ? loaderIconMap[variant] : BiAnalyse;
  return <Icon className={`loading-${size} text-${color} ${className}`} style={style} />;
};

Loader.defaultProps = defaultProps;

export default Loader;
