import { RootState } from '../index';
import { ObjectFormState } from './objectFormSlice';
import { FormState, FormTabs } from '../../ui/shared/models/FormStates';

const getObjectFormState = (state: RootState): ObjectFormState => state.objectFormState;

const getActiveTab = (state: RootState): FormTabs => state.objectFormState.activeTab;

const getFormState = (state: RootState): FormState => state.objectFormState.formState;

const getChosenForm = (state: RootState): string | null => state.objectFormState.chosenForm;

export { getObjectFormState, getActiveTab, getFormState, getChosenForm };
