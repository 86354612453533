import { onError } from '@apollo/client/link/error';
import { useDispatch } from 'react-redux';
import { makeUniqueId } from '@apollo/client/utilities';
import { BiErrorCircle } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { fromPromise } from '@apollo/client';
import { useContext } from 'react';
import useAuth from '../../../hooks/useAuth';
import { actions } from '../../../../store';
import { envVars } from '../../../../environments/env';
import logIn from '../../../core/Authentication/services/authentication-service';
import AuthProvider from '../../../core/Authentication/components/AuthProvider/AuthProvider';
import AuthContext from '../../../contexts/AuthContext';

const useErrorHandlerLink = () => {
  const { logOut, logIn: setUser } = useAuth();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reAuth = async (): Promise<string | null> => {
    const [username, password] = envVars.REACT_APP_CREDENTIALS.split('|');

    const { data } = await logIn({ username, password });
    if (setUser) {
      setUser(data);
      localStorage.setItem('userData', JSON.stringify(data));
    }
    return data?.jwt ?? null;
  };

  const errorHandler = onError(({ networkError, graphQLErrors, operation, forward }) => {
    if (networkError) {
      const statusCode = networkError.message.split(' ').pop();
      dispatch(
        actions.addNotification({
          status: 'error',
          body: statusCode === '401' ? 'Ошибка авторизации' : t('general.errors.connectionError'),
          header: t('general.errors.error'),
          statusIcon: BiErrorCircle,
          delay: 5000,
          id: 'err-network',
        })
      );
      if (statusCode === '401' && logOut) logOut();
      return forward(operation);
    }

    if (graphQLErrors?.some((err) => err.extensions?.classification === 'UNAUTHORIZED') && logOut) {
      if (envVars.REACT_APP_CREDENTIALS) {
        return fromPromise(reAuth()).flatMap((jwt) => {
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: jwt ? `Bearer ${jwt}` : null,
            },
          });
          return forward(operation);
        });
      }
      logOut();
      return forward(operation);
    }

    graphQLErrors?.forEach((err) => {
      if (
        err.extensions?.classification !== 'ValidationError' &&
        err.extensions?.classification !== 'INTERNAL_ERROR' &&
        err.extensions?.classification !== 'FORBIDDEN' &&
        (err.extensions?.classification as string) !== 'LIST_ERROR'
      ) {
        dispatch(
          actions.addNotification({
            status: 'error',
            body: err.message,
            header: t('general.errors.error'),
            statusIcon: BiErrorCircle,
            delay: 5000,
            id: makeUniqueId('err-'),
          })
        );
        return;
      }

      if (err.extensions?.classification === 'FORBIDDEN') {
        dispatch(
          actions.addNotification({
            status: 'error',
            body: err.message || t('general.errors.accessRestricted'),
            header: t('general.errors.error'),
            statusIcon: BiErrorCircle,
            delay: 5000,
            id: makeUniqueId('err-'),
          })
        );
      }

      if (err.extensions?.classification === 'INTERNAL_ERROR') {
        dispatch(
          actions.addNotification({
            status: 'error',
            body: t('general.errors.serverError'),
            header: t('general.errors.error'),
            statusIcon: BiErrorCircle,
            delay: 5000,
            id: 'internal-err',
          })
        );
      }

      if ((err.extensions?.classification as any) === 'LIST_ERROR') {
        (err.extensions?.key as string[]).forEach((text) => {
          dispatch(
            actions.addNotification({
              status: 'error',
              body: text,
              header: err.message,
              statusIcon: BiErrorCircle,
              delay: 5000,
              id: makeUniqueId('err-'),
            })
          );
        });
      }
    });
  });
  return errorHandler;
};
export default useErrorHandlerLink;
