export default {
  testModal: 'testModal',
  cadastralObjectModal: 'cadastralObjectModal',
  infSelectModal: 'infSelectModal',
  processStatusModal: 'processStatusModal',
  boFiltersModal: 'boFiltersModal',
  saveFilterLayerModal: 'saveFilterLayerModal',
  allSavedFiltersLayerModal: 'allSavedFiltersLayerModal',
  layerEditModal: 'layerEditModal',
};
