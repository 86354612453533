import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataSourceKind } from '../../generated/graphql';

export type registriesView = 'MAH' | 'RU_BORDER';

export interface registriestate {
  viewPage: DataSourceKind;
}

const initialRegState: registriestate = {
  viewPage: DataSourceKind.RuBorder,
};

const registrieslice = createSlice({
  name: 'registrieslice',
  initialState: initialRegState,
  reducers: {
    handleChangeregistriesView(draftState, action: PayloadAction<{ viewPage: DataSourceKind }>) {
      draftState.viewPage = action.payload.viewPage;
    },
  },
});

export const { actions } = registrieslice;
export default registrieslice.reducer;
