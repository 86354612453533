class Observable<T> {
  listeners = new Set<any>();

  value!: T;

  constructor(value: T) {
    this.value = value;
  }

  get(): T {
    return this.value;
  }

  set(newValue: T): void {
    if (newValue !== this.value) {
      this.notify(newValue);
    }
  }

  subscribe(listener: unknown): void {
    this.listeners.add(listener);
  }

  unsubscribe(listener: unknown): void {
    this.listeners.delete(listener);
  }

  notify(newValue: T): void {
    // @ts-ignore
    for (const listener of this.listeners) {
      listener(newValue);
    }
  }
}
export default Observable;
