import { Menu, MenuProps } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from '../types/menu-item';
import { actions } from '../../../../store';
import useAuth from '../../../hooks/useAuth';
import { pagesRoutesMap } from '../../../pages/routes';
import './MenuComponent.scss';

interface SiderMenuProps {
  readonly menuItems: MenuItem[];
  readonly menuSelectionEvent: (key: string) => void;
  readonly mode?: any;
  readonly defaultSelected?: string;
  readonly highlightSelected?: boolean;
}

const MenuComponent: React.FC<SiderMenuProps> = ({
  menuItems,
  menuSelectionEvent,
  defaultSelected = '',
  highlightSelected = true,
  mode = 'inline',
}) => {
  const [current, setCurrent] = React.useState(defaultSelected);
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { logOut } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    setCurrent(defaultSelected);
  }, [defaultSelected]);

  const handleLogout = async (): Promise<void> => {
    dispatch(actions.resetMap());
    await client.cache.reset();
    (logOut as any)();
  };

  const onClick: MenuProps['onClick'] = async (event) => {
    const { key, keyPath } = event;
    if (key === 'logout') {
      await handleLogout();
      return;
    }

    const profilePath = pagesRoutesMap.find((route) => route.key === 'profile')?.path;
    if (key === 'profile' && profilePath) {
      navigate(profilePath);
    }

    const findProperty = pagesRoutesMap.find(
      (route) => route.key === keyPath[keyPath.length - 1]
    )?.path;
    if (findProperty) navigate(findProperty);
    setCurrent(key);
  };

  return (
    <Menu
      selectedKeys={[highlightSelected ? current : '']}
      onClick={onClick}
      theme="light"
      mode={mode}
      items={menuItems}
    />
  );
};

export default MenuComponent;
