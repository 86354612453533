import Cookies from 'js-cookie';

const getUser = (): Record<string, never> => {
  const savedUser = Cookies.get('currentUser');

  if (savedUser === undefined) {
    return {};
  }

  return JSON.parse(savedUser);
};

export default getUser;
