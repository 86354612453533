import { lazy } from 'react';
import { RouteConfig } from '../../interfaces/Route';
import { Roles } from '../../generated/graphql';

const routes = {
  logInPagePath: (): string => '/login',
  mapPagePath: (): string => '/',
  profilePagePath: (): string => '/profile',
  usersPagePath: (): string => '/users',
  userPagePath: (): string => '/users/:id',
  newUserPagePath: (): string => '/users/:id',
  userByIdPagePath: (id: number | string): string => `users/${id}`,
  logInPath: (): string => 'auth/login',
  userById: (id: number | string): string => `auth/user?userId=${id}`,
  addUser: (): string => 'auth/register',
  getFileById: (): string => 'files/load',
  getFiles: (): string => 'files/list',
  addFile: (): string => 'files/uploadV2',
  deleteFile: (): string => 'files/deleteByIdV2',
  analytics: (): string => '/analytics',
  backOffice: (): string => '/backoffice',
  registries: (): string => '/registries',
  redirectReg: (): string => '/registriesType',
};

export const pagesRoutesMap: readonly RouteConfig[] = [
  {
    key: 'map',
    path: routes.mapPagePath(),
    isPrivate: true,
    title: 'nav.mapPage',
    permission: Roles.Ant,
    Component: lazy(() => import('./Map')),
  },
  {
    key: 'profile',
    path: routes.profilePagePath(),
    isPrivate: true,
    title: null,
    permission: true,
    Component: lazy(() => import('./Profile')),
  },
  {
    key: 'login',
    path: routes.logInPagePath(),
    isPrivate: false,
    title: null,
    permission: true,
    Component: lazy(() => import('./LogIn')),
  },
  {
    key: 'users',
    path: routes.usersPagePath(),
    isPrivate: true,
    title: 'nav.users',
    permission: Roles.AdminUser,
    Component: lazy(() => import('./Users')),
  },
  {
    key: 'user',
    path: routes.userPagePath(),
    isPrivate: true,
    title: null,
    permission: Roles.AntOfficer,
    Component: lazy(() => import('./User')),
  },
  {
    key: 'users',
    path: routes.newUserPagePath(),
    isPrivate: true,
    title: null,
    permission: Roles.AntOfficer,
    Component: lazy(() => import('./User')),
  },
  {
    key: 'analytics',
    path: routes.analytics(),
    isPrivate: true,
    title: 'nav.analytics',
    permission: [Roles.Viewer, Roles.AdminUser, Roles.Developer, Roles.Ant],
    Component: lazy(() => import('./Analytics')),
  },
  {
    key: 'backOffice',
    path: routes.backOffice(),
    isPrivate: true,
    title: 'nav.backOffice',
    permission: [Roles.Viewer, Roles.BackOffice, Roles.AdminUser],
    Component: lazy(() => import('./BackOffice')),
  },
  {
    key: 'registries',
    path: routes.registries(),
    isPrivate: true,
    title: 'nav.registries',
    permission: [Roles.AdminUser, Roles.Registry],
    Component: lazy(() => import('./Registries')),
  },
  {
    key: 'redirectReg',
    path: routes.redirectReg(),
    isPrivate: false,
    title: null,
    permission: [Roles.AdminUser, Roles.Registry],
    Component: lazy(() => import('./RegRedirect')),
  },
];

export default routes;
