import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import ModalContainer from './components/ModalContainer';
import { selectors } from '../../../store';

const Modals = (): ReactElement | null => {
  const kind = useSelector(selectors.getCurrentModalKind);
  return kind ? <ModalContainer kind={kind} /> : null;
};

export default Modals;
