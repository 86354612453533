import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import WarningModalContext from './WarningModalContext';

const WarningModalView = (props: any) => {
  const { onClose } = props;
  const { modal } = useContext(WarningModalContext);

  const onActionClick = () => {
    modal?.action();
    onClose();
  };

  return (
    <div className="warning-modal card better-card w-50 p-4">
      <div>{modal?.header && <div>{modal.header}</div>}</div>
      <div>{modal?.body && <div>{modal.body}</div>}</div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <Button variant="danger" onClick={onClose}>
          Отмена
        </Button>
        <Button onClick={onActionClick}>Подтвердить</Button>
      </div>
    </div>
  );
};
export default WarningModalView;
