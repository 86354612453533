import { RootState } from '../../index';
import { SourceState } from './sourceSlice';
import { GroupDto, InTaskStatusInput, Layer, LayerType } from '../../../generated/graphql';

const getSourceState = (state: RootState): SourceState => state.sourceState;

const getLayerSettings = (state: RootState): Layer[] => state.sourceState.layersSettings;

const getGroupSettings = (state: RootState): GroupDto[] => state.sourceState.groupSettings;

const getVisibleLayers = (state: RootState): string[] => state.sourceState.visibleLayers;

const getActivityLayerId = (state: RootState): string | null => state.sourceState.activeLayerId;

const getNew = (state: RootState): Layer | GroupDto | null => state.sourceState.newLayout;

const geNewLayerId = (state: RootState): string => state.sourceState.newLayerId;

const getRefreshObjectsHandler = (state: RootState): boolean =>
  state.sourceState.refreshObjectsHandler;

const getDefaultLayers = (state: RootState): Layer[] =>
  state.sourceState.layersSettings.filter((layer) => layer.layerType === LayerType.SystemDefault);

const getLayerStatus = (state: RootState): InTaskStatusInput | null | boolean =>
  state.sourceState.layerStatus;

export {
  getSourceState,
  getLayerSettings,
  getGroupSettings,
  getVisibleLayers,
  getActivityLayerId,
  getNew,
  geNewLayerId,
  getRefreshObjectsHandler,
  getDefaultLayers,
  getLayerStatus,
};
