import { useMemo, ReactElement, FC } from 'react';
import { useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';
import AuthContext from '../../../../contexts/AuthContext';
import { AuthContextData } from '../../../../../interfaces/AuthContext';
import { actions } from '../../../../../store';
import useInterceptorsReq from '../../services/useInterceporsReq';

interface AuthProviderProps {
  readonly children: ReactElement;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const interceptors = useInterceptorsReq();
  const logOut = async (): Promise<void> => {
    localStorage.removeItem('userData');
    interceptors.setUser(null);
    await client.resetStore();
    dispatch(actions.handleFormState('closed'));
    dispatch(actions.changeActiveTab('form'));
  };

  const authData = useMemo(
    () =>
      ({
        logIn: interceptors.setUser,
        logOut,
        user: interceptors.user?.userDto,
        token: interceptors.user?.jwt,
      } as AuthContextData),
    [interceptors.user]
  );

  return <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
