import { CgProfile } from 'react-icons/cg';
import React from 'react';
import { BiExit } from 'react-icons/bi';
import useMenuItemsConfig from './useMenuItemsConfig';
import { MenuItem } from '../types/menu-item';
import { getMenuItem, getProfileMenuItem } from '../helpers/get-menu-items';
import routes from '../../../pages/routes';
import useAuth from '../../../hooks/useAuth';
import { envVars } from '../../../../environments/env';
import useRoles from '../../../hooks/useRoles';

const getItem = ({
  label,
  key,
  icon,
  children,
  type,
  disabled,
  onClick,
}: {
  label: React.ReactNode;
  key: React.Key | null;
  icon?: React.ReactNode;
  children?: MenuItem[];
  type?: 'group';
  onClick?: () => void;
  disabled?: boolean;
}): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
    onClick,
    disabled,
  } as MenuItem;
};

const getItemWithChildren = (item: any) => {
  return getItem({
    label: item.title,
    key: item.key,
    icon: item.icon,
    onClick: item.onClick,
    type: item.type,
    disabled: item?.disabled,
    children: item?.children && item.children.map(getItemWithChildren),
  });
};

const useMenuItems = () => {
  const { user } = useAuth();
  const { isBACK_OFFICE: isBackOffice } = useRoles();
  const MENU_ITEMS_CONFIG = useMenuItemsConfig();
  const MENU_ITEMS: MenuItem[] = MENU_ITEMS_CONFIG.map((item: any) =>
    getMenuItem({
      role: item.role,
      label: item.title,
      path: item.route,
      key: item.key,
      icon: item.icon,
      type: item?.type,
      children: !isBackOffice(false) && item.children && item.children.map(getItemWithChildren),
      onClick: item?.onClick,
      onTitleClick: item?.onTitleClick,
    })
  );

  const PROFILE_MENU_ITEM: MenuItem[] = [
    getProfileMenuItem({
      label: 'Профиль',
      key: routes.profilePagePath(),
      icon: <CgProfile />,
      onTitleClick: null,
      children: [
        getProfileMenuItem({
          label: user?.username,
          key: undefined,
          icon: null,
          onTitleClick: null,
          children: [
            getProfileMenuItem({ label: 'Профиль', key: 'profile', onTitleClick: null }),
            ...[
              getProfileMenuItem({
                label: 'Выход',
                key: 'logout',
                icon: <BiExit />,
                onTitleClick: null,
              }),
            ].filter(() => !envVars.REACT_APP_CREDENTIALS),
          ],
          type: 'group',
        }),
      ],
    }),
  ];
  return { MENU_ITEMS, PROFILE_MENU_ITEM };
};

export default useMenuItems;
