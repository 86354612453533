import { Modal } from 'react-bootstrap';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import LayerEditForm from '../../Map/components/MapControls/AddLayer/LayerEditForm';

export function LayerEditModal({ data, onHide }: { data: any; onHide: any }): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t(`map.layerControls.${data?.action || 'addNewLayer'}`)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <LayerEditForm data={data} onHide={onHide} />
      </Modal.Body>
    </>
  );
}
