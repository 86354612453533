import { GetGeoOutput } from './types';
import { db } from './db';
import { GeometryResponseDto } from '../generated/graphql';

const addToDb = (data: GeometryResponseDto[]) => {
  db.geo
    .bulkPut(
      data.map((geo) => ({
        ...geo,
        id: String(geo.id),
        workBorderId: String(geo.workBorderId),
      }))
    )
    .catch((error) => {
      console.error('ERROR OF DownloadDB', error);
    });
};

export default addToDb;
