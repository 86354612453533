import { createContext, ReactNode } from 'react';

export interface MenuItemConfig {
  key: string;
  title: string;
  route: string;
  icon?: ReactNode;
}

export const MenuContext = createContext({ collapse: true });
