import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import Filter from '../../BackOffice/TaskObjectsView/components/Filter/Filter';
import { ModalProps } from '../interfaces/ModalProps';

const BackOfficeFiltersModal = (props: ModalProps): ReactElement => {
  const { data, onHide } = props;
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('backOffice.modals.filters')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Filter
          onChange={data?.onChange}
          onAddressChange={data?.onAddressChange}
          onRegionChange={data?.onRegionChange}
          onFieldFiltersChange={data?.onFieldFiltersChange}
          onSubmitFn={onHide}
          data={data}
        />
      </Modal.Body>
    </>
  );
};
export default BackOfficeFiltersModal;
