import { ApolloLink, from, NextLink, Operation, useApolloClient } from '@apollo/client';
import React, { useEffect } from 'react';
import { makeUniqueId } from '@apollo/client/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
import Notification from './Notification';
import './Notifications.scss';
import Notifications from '../../models/Notifications/Notifications';
import {
  NotificationStatus,
  NotificationWithId,
} from '../../models/Notifications/NotificationModel';
import { actions, selectors } from '../../../../store';
import useErrorHandlerLink from './useErrorHandlerLink';
import usePortal from '../../../hooks/usePortal';

const NotificationsContainer = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const notifications = useSelector(selectors.getNotificationsState);
  const { removePortal } = usePortal('notify-container');

  const errorHandler = useErrorHandlerLink();

  const removeNotification = (id: string, status: NotificationStatus) => {
    dispatch(actions.removeNotification({ id, status }));
  };

  const addNotification = (notify: NotificationWithId) => {
    dispatch(actions.addNotification(notify));
  };

  const linkHandler = (forward: NextLink, operation: Operation) => {
    return forward(operation).map((result) => {
      if (result?.data && Object.values(result.data).every((value: any) => !!value)) {
        const notify = Notifications[operation.operationName];
        if (notify) {
          addNotification({ ...notify, id: makeUniqueId('not-') });
        }
      }
      return result;
    });
  };

  useEffect(() => {
    const notificationsLink = new ApolloLink((operation, forward) =>
      linkHandler(forward, operation)
    );

    client.setLink(from([errorHandler, notificationsLink, client.link]));
  }, []);

  useEffect(() => {
    if (!uniqBy(Object.values(notifications).flat(), 'id').length) {
      removePortal();
    }
  }, [notifications]);

  return (
    <div>
      {uniqBy(Object.values(notifications).flat(), 'id').map((not) => (
        <Notification key={not.id} notification={not} id={not.id} onClose={removeNotification} />
      ))}
    </div>
  );
};
export default NotificationsContainer;
