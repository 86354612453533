import { ReactElement } from 'react';
import { BiCommentError } from 'react-icons/bi';
import { TfiReload } from 'react-icons/tfi';
import './ErrorBoundary.scss';
import { useTranslation } from 'react-i18next';

const ErrorPage = (): ReactElement => {
  const heandleReload = (): void => {
    window.location.reload();
  };
  const { t } = useTranslation();
  return (
    <div className="error-root">
      <div className="error-block">
        <BiCommentError className="icon" size={150} />
        <span className="error-text">{t('errorElement.errorMessage')}</span>
        <button type="button" className="refresh" onClick={heandleReload}>
          <TfiReload size={50} />
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
