import axios, { AxiosResponse } from 'axios';
import { searchFilterObject } from '../components/SmartFilterItem/useSmartFilterData';
import { DataSourceKind, EntitySearchSubtype } from '../../../../../../generated/graphql';
import getEnvs from '../../../../../../environments/env';
import { RightsInput } from '../../../types/IRegisters';

// TODO: deprecate empty promise
const emptyPromise = Promise.reject();

const { api, serverAddressMah, v1, v2 } = getEnvs();

export const getSources = (viewPageConfig: any): Promise<AxiosResponse> => {
  if (viewPageConfig?.address) return axios.get(`${viewPageConfig.address}${v1}/sources`);
  return emptyPromise;
};

export const getAllKits = (viewPageConfig: any): Promise<AxiosResponse> => {
  if (viewPageConfig?.address) return axios(`${viewPageConfig.address}${v1}/set/all`);
  return emptyPromise;
};

export const getAllGeo = (
  type: EntitySearchSubtype,
  viewPageConfig: any
): Promise<AxiosResponse> => {
  if (viewPageConfig?.address)
    return axios.get(`${viewPageConfig.address}${viewPageConfig.version}/geometries/${type}`);
  return emptyPromise;
};

export const getRights = (data: RightsInput): Promise<AxiosResponse> => {
  if (serverAddressMah) return axios.post(`${serverAddressMah}${v1}/rights`, data);
  return emptyPromise;
};

export const getEncumbrance = (data: RightsInput): Promise<AxiosResponse> => {
  if (serverAddressMah) return axios.post(`${serverAddressMah}${v1}/encumbrance`, data);
  return emptyPromise;
};

export const getGeoPoints = (
  object: searchFilterObject,
  viewPageConfig: any
): Promise<AxiosResponse> => {
  if (viewPageConfig?.address)
    return axios.post(`${viewPageConfig.address}${viewPageConfig.version}/search/geo`, object);
  return emptyPromise;
};

export const getFilteredData = (
  object: searchFilterObject,
  viewPageConfig: any
): Promise<AxiosResponse> => {
  if (viewPageConfig?.address) return axios.post(`${viewPageConfig.address}${v1}/search`, object);
  return emptyPromise;
};

export const setFilterLayer = (object: {
  filterName: string;
  dto: searchFilterObject;
}): Promise<AxiosResponse> => {
  return axios.post(`${api}/search-query/store`, object);
};

export const getFiltersForRegistry = (registryId: string): Promise<AxiosResponse> => {
  return axios.get(`${api}/search-query?registryId=${registryId}`);
};

export const getSuggestValue = (
  value: string,
  type: DataSourceKind,
  subtype: EntitySearchSubtype,
  fieldValue?: string,
  field?: string,
  viewPageConfig?: any
): Promise<AxiosResponse> => {
  const object = {
    entityType: {
      type,
      subtype,
    },
    count: 15,
    field: fieldValue || field,
    value,
  };
  if (viewPageConfig?.address) return axios.post(`${viewPageConfig.address}${v1}/suggest`, object);
  return emptyPromise;
};

export const getPointInformation = (
  subtype: string,
  pointId: string,
  viewPageConfig: any
): Promise<AxiosResponse> => {
  if (viewPageConfig?.address)
    return axios.get(`${viewPageConfig.address}${v2}/geometry/${subtype}/${pointId}`);
  return emptyPromise;
};

export const deleteFilters = (id: any): Promise<AxiosResponse> => {
  return axios.delete(`${api}/search-query?filterId=${id}`);
};
