/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntitySubtypes } from '../../ui/shared/models/EntitySubtypes';

type ModalOpenState = 'opened' | 'closed' | 'initial';
type ModalSize = 'sm' | 'xl' | 'lg';

export interface ModalState {
  modal: {
    data: any;
    state: ModalOpenState;
    kind: string | null;
    size: ModalSize;
    tableType: string;
  };
}

const initialModalState: ModalState = {
  modal: {
    data: null,
    state: 'initial',
    kind: null,
    size: 'sm',
    tableType: EntitySubtypes.HOMEOWNERSHIP.value,
  },
};

const modalsSlice = createSlice({
  name: 'modalsInfo',
  initialState: initialModalState,
  reducers: {
    openModal(
      draftState,
      action: PayloadAction<{
        readonly data?: any;
        readonly kind: string;
        readonly size?: ModalSize;
        readonly tableType?: any;
      }>
    ) {
      const { data, kind, size, tableType } = action.payload;
      draftState.modal.state = 'opened';
      draftState.modal.kind = kind;
      draftState.modal.data = data || initialModalState.modal.data;
      draftState.modal.size = size || initialModalState.modal.size;
      draftState.modal.tableType = tableType;
    },
    hideModal(draftState) {
      draftState.modal = initialModalState.modal;
    },
  },
});

export const { actions } = modalsSlice;
export default modalsSlice.reducer;
