import axios, { AxiosResponse } from 'axios';
import { LogInData } from '../interfaces/LogInData';
import routes from '../../../pages/routes';
import createPath from '../../../../utils/createPath';
import { UserData } from '../interfaces/UserData';
import getEnvs from '../../../../environments/env';

const { auth } = getEnvs();

const logIn = (logInData: LogInData): Promise<AxiosResponse<UserData>> => {
  return axios.post<UserData>(createPath([auth, routes.logInPath()]), logInData);
};

export default logIn;
