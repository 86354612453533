import { Feature } from 'ol';
import { RootState } from '../../index';
import { IContextMenu, InteractionsState } from './interactionsSlice';
import { DrawKind } from '../../../ui/shared/models/DrawKinds';
import { MainActionsState } from '../../../ui/shared/models/MainActionsState';

const getInteractionsState = (state: RootState): InteractionsState => state.interactionsState;

const getDrawKind = (state: RootState): DrawKind => state.interactionsState.drawKind;

const getSelected = (state: RootState): Feature[] => {
  return state.interactionsState.selected.getArray();
};
const getContextMenu = (state: RootState): IContextMenu => {
  return state.interactionsState.contextMenu;
};
const getExtentCoords = (state: RootState): any => state.interactionsState.extentCoords;

const getMainActionsState = (state: RootState): MainActionsState =>
  state.interactionsState.mainActionsState;

export {
  getInteractionsState,
  getDrawKind,
  getSelected,
  getExtentCoords,
  getMainActionsState,
  getContextMenu,
};
