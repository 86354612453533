import { useState } from 'react';
import WarningModal from './WarningModal';
import WarningModalContext from './WarningModalContext';
import { WarningModalInterface } from './WarningModalTypes';

const WarningModalProvider = ({ children }: { children: any }) => {
  const [modal, setModal] = useState<WarningModalInterface | null>(null);

  return (
    <WarningModalContext.Provider value={{ modal, setModal }}>
      {modal && <WarningModal />}
      {children}
    </WarningModalContext.Provider>
  );
};
export default WarningModalProvider;
