import { ProcessingStatusValue } from '../../../../../generated/graphql';

export type TOptionsProcessStatusObject = { label: string; value: any; order: number };
type TProcessStatusObject = Record<ProcessingStatusValue | any, TOptionsProcessStatusObject>;

export const CommonProcessStatusObject: TProcessStatusObject = {
  ANALYZE: {
    label: 'Анализ',
    value: ProcessingStatusValue.Analyze,
    order: 12,
  },
  ASK_ANT: {
    label: 'Отправлен запрос обходчику',
    value: ProcessingStatusValue.AskAnt,
    order: 33,
  },
  ASK_OWNER: {
    label: 'Отправлен запрос собственнику',
    value: ProcessingStatusValue.AskOwner,
    order: 7,
  },
  COURT_DECISION: {
    label: 'Решение суда',
    value: ProcessingStatusValue.CourtDecision,
    order: 16,
  },
  DID_NOT_ANSWER: {
    label: 'Недозвон',
    value: ProcessingStatusValue.DidNotAnswer,
    order: 8,
  },
  DOCUMENTS_IN_THE_MSDC: {
    label: 'Отдал документы в МФЦ',
    value: ProcessingStatusValue.DocumentsInTheMsdc,
    order: 18,
  },
  DRAWS_UP_LEASE_AGREEMENT: {
    label: 'Оформляет договор аренды',
    value: ProcessingStatusValue.DrawsUpLeaseAgreement,
    order: 23,
  },
  FILED_DOCUMENTS_WITH_THE_COURT: {
    label: 'Направил документы в суд',
    value: ProcessingStatusValue.FiledDocumentsWithTheCourt,
    order: 15,
  },
  FNS_DB: {
    label: 'В базе ФНС',
    value: ProcessingStatusValue.FnsDb,
    order: 30,
  },
  GOT_PAPERS_FROM_MSDC: {
    label: 'Получил выписку из ЕГРН',
    value: ProcessingStatusValue.GotPapersFromMsdc,
    order: 3,
  },
  IN_PROPERTY: {
    label: 'Собственность',
    value: ProcessingStatusValue.InProperty,
    order: 2,
  },
  NEED_OWNER_PHONE: {
    label: 'Требуется телефон собственника',
    value: ProcessingStatusValue.NeedOwnerPhone,
    order: 6,
  },
  NONE: {
    label: 'Отсутствует',
    value: ProcessingStatusValue.None,
    order: 1,
  },
  NOTIFICATION_BY_PHONE: {
    label: 'Уведомление озвучено по телефону (нет ватсапа)',
    value: ProcessingStatusValue.NotificationByPhone,
    order: 32,
  },
  NOT_TAXED: {
    label: 'Не облагается налогом',
    value: ProcessingStatusValue.NotTaxed,
    order: 4,
  },
  ORDERED_CADASTRAL_WORKS: {
    label: 'Собственник заказал кадастровые работы',
    value: ProcessingStatusValue.OrderedCadastralWorks,
    order: 17,
  },
  PROMISED_TO_PROVIDE_DOCUMENTS: {
    label: 'Обещал предоставить документы',
    value: ProcessingStatusValue.PromisedToProvideDocuments,
    order: 10,
  },
  READY_TO_PAPERWORK: {
    label: 'Готов оформить документы',
    value: ProcessingStatusValue.ReadyToPaperwork,
    order: 9,
  },
  RECEIVED_DOCUMENT_FROM_ADMINISTRATION: {
    label: 'Получил правоустанавливающий документ в администрации',
    value: ProcessingStatusValue.ReceivedDocumentFromAdministration,
    order: 22,
  },
  REFUSED_TO_COOPERATE: {
    label: 'Отказывается оформить документы',
    value: ProcessingStatusValue.RefusedToCooperate,
    order: 19,
  },
  REFUSE_TO_PROVIDE_DOCUMENTS: {
    label: 'Отказывается предоставить документы',
    value: ProcessingStatusValue.RefuseToProvideDocuments,
    order: 20,
  },
  REGISTRATION_HAS_BEGUN: {
    label: 'Начато оформление',
    value: ProcessingStatusValue.RegistrationHasBegun,
    order: 21,
  },
  RENT: {
    label: 'Аренда',
    value: ProcessingStatusValue.Rent,
    order: 24,
  },
  RESERVE: {
    label: 'Резерв',
    value: ProcessingStatusValue.Reserve,
    order: 28,
  },
  SEND_ADMINISTRATION: {
    label: 'Отправлен запрос в администрацию поселения',
    value: ProcessingStatusValue.SendAdministration,
    order: 26,
  },
  SEND_FNS: {
    label: 'Отправлен запрос в ФНС',
    value: ProcessingStatusValue.SendFns,
    order: 29,
  },
  SEND_OWNER: {
    label: 'Отправлено уведомление собственнику',
    value: ProcessingStatusValue.SendOwner,
    order: 31,
  },
  SEND_REGION: {
    label: 'Отправлен запрос в администрацию района',
    value: ProcessingStatusValue.SendRegion,
    order: 27,
  },
  SENT_FOR_OMSU: {
    label: 'Направлен на приём в ОМСУ',
    value: ProcessingStatusValue.SentForOmsu,
    order: 14,
  },
  SENT_IN_MAYOR_OFFICE: {
    label: 'Отправлен запрос в мэрию',
    value: ProcessingStatusValue.SentInMayorOffice,
    order: 13,
  },
  SMO_MEMBER: {
    label: 'Участник СВО',
    value: ProcessingStatusValue.SmoMember,
    order: 11,
  },
  UNPAID_URGENT_USAGE: {
    label: 'Безвозмездное срочное пользование',
    value: ProcessingStatusValue.UnpaidUrgentUsage,
    order: 25,
  },
  WITHDRAWN_FROM_ACCOUNTING: {
    label: 'Выведен из кадастра',
    value: ProcessingStatusValue.WithdrawnFromAccounting,
    order: 5,
  },
};

export const AdministrationProcessStatusObject: TProcessStatusObject = {
  ALLOW_REGISTRATION: {
    label: 'Разрешить оформление',
    value: ProcessingStatusValue.AllowRegistration,
    order: 98,
  },
  NEED_TIME_TO_CHECK: {
    label: 'Требуется время для проверки',
    value: ProcessingStatusValue.NeedTimeToCheck,
    order: 99,
  },
  DENY_REGISTRATION: {
    label: 'Отказ в оформлении',
    value: ProcessingStatusValue.DenyRegistration,
    order: 100,
  },
};
