import { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import {
  NotificationModel,
  NotificationStatus,
} from '../../models/Notifications/NotificationModel';
import usePortal from '../../../hooks/usePortal';

const Notification = ({
  notification,
  id,
  onClose,
}: {
  notification: NotificationModel;
  id: string;
  onClose: (notId: string, status: NotificationStatus) => void;
}) => {
  const { element: notifyContainer } = usePortal('notify-container');
  const [onCloseAnimation, setOnCloseAnimation] = useState('');

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (notification) {
      timeout = setTimeout(() => {
        onClose(id, notification.status);
      }, notification.delay);
    }

    const closeTimer = setTimeout(() => {
      setOnCloseAnimation('close-notification');
    }, notification.delay - 500);

    return () => {
      clearTimeout(timeout);
      clearTimeout(closeTimer);
    };
  }, [notification]);

  const Icon = notification?.statusIcon as any;

  const notificationBody = (
    <Toast
      className={`not-${notification.status} ${onCloseAnimation}`}
      datatest-id={`notification-${notification.status}`}
      style={{ textAlign: 'right', transition: '0.5s' }}
      id={id}
    >
      {!!notification?.header && (
        <Toast.Header
          className={`justify-content-end border-bottom-0 not-${notification.status}`}
          closeButton={false}
        >
          <strong>{notification.header}</strong>
        </Toast.Header>
      )}
      {!!notification?.body && (
        <Toast.Body className="d-flex justify-content-between align-items-center">
          {Icon && <Icon style={{ minWidth: 24, minHeight: 24 }} />}
          {notification.body}
        </Toast.Body>
      )}
    </Toast>
  );

  return notification && createPortal(notificationBody, notifyContainer);
};
export default Notification;
