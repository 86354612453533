import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Collection, Feature } from 'ol';
import { DrawKind } from '../../../ui/shared/models/DrawKinds';
import { MainActionsState } from '../../../ui/shared/models/MainActionsState';

export interface IContextMenu {
  showContextMenu: boolean;
  anchorPoint: { x: number; y: number };
  fProperties: any[];
  selectedFeatures: Feature[];
  isActive: boolean;
}
export interface InteractionsState {
  drawKind: DrawKind;
  selected: any;
  extentCoords: any;
  mainActionsState: MainActionsState;
  contextMenu: IContextMenu;
}

const initialInteractionsState: InteractionsState = {
  drawKind: 'Polygon',
  selected: new Collection(),
  extentCoords: [[[]]],
  mainActionsState: 'select',
  contextMenu: {
    showContextMenu: false,
    anchorPoint: { x: 0, y: 0 },
    fProperties: [],
    selectedFeatures: [],
    isActive: false,
  },
};

const interactionsSlice = createSlice({
  name: 'interactionsInfo',
  initialState: initialInteractionsState,
  reducers: {
    changeExtentCoords(draftState, action: PayloadAction<any>) {
      draftState.extentCoords = action.payload;
    },
    newSelected(draftState, action: PayloadAction<Collection<any>>) {
      draftState.selected = action.payload;
    },
    changeDrawKind(draftState, action: PayloadAction<DrawKind>) {
      draftState.drawKind = action.payload;
    },
    changeMainAction(draftState, action: PayloadAction<MainActionsState>) {
      draftState.mainActionsState = action.payload;
    },
    changeShowContextMenu(draftState, action: PayloadAction<boolean>) {
      draftState.contextMenu.showContextMenu = action.payload;
    },

    changeFPropsContextMenu(draftState, action: PayloadAction<any[]>) {
      draftState.contextMenu.fProperties = action.payload;
    },

    changeAnchorContextMenu(draftState, action: PayloadAction<{ x: number; y: number }>) {
      draftState.contextMenu.anchorPoint = action.payload;
    },
    changeSelectedContextMenu(draftState, action: PayloadAction<Feature[]>) {
      draftState.contextMenu.selectedFeatures = action.payload;
    },

    changeActiveContextMenu(draftState, action: PayloadAction<boolean>) {
      draftState.contextMenu.isActive = action.payload;
    },
  },
});

export const { actions } = interactionsSlice;
export default interactionsSlice.reducer;
