import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  NotificationStatus,
  NotificationWithId,
} from '../../ui/shared/models/Notifications/NotificationModel';

export type NotificationsState = Record<NotificationStatus, NotificationWithId[]>;

const initialState: NotificationsState = {
  success: [],
  error: [],
  warning: [],
  loading: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(draftState, action: PayloadAction<NotificationWithId>) {
      draftState[action.payload.status] = [...draftState[action.payload.status], action.payload];
    },
    removeNotification(
      draftState,
      action: PayloadAction<{ id: string; status: NotificationStatus }>
    ) {
      draftState[action.payload.status] = draftState[action.payload.status]?.filter(
        (notification) => notification.id !== action.payload.id
      );
    },
  },
});

export const { actions } = notificationsSlice;
export default notificationsSlice.reducer;
