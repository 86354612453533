import { ReactElement } from 'react';
import SaveFilterLayerView from './SaveFilterLayerView';
import { searchFilterObject } from '../SmartFilter/components/SmartFilterItem/useSmartFilterData';

const SaveFilterLayer = ({
  data,
  onHide,
}: {
  data: searchFilterObject;
  onHide: () => void;
}): ReactElement => {
  return <SaveFilterLayerView filter={data} onHide={onHide} />;
};
export default SaveFilterLayer;
