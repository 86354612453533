import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import { ModalProps } from '../interfaces/ModalProps';
import SaveFilterLayer from '../../Registries/components/SaveFilterLayer/SaveFilterLayer';

const SaveFilterLayerModal = (props: ModalProps): ReactElement => {
  const { data, onHide } = props;
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('registries.saveFilter')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <SaveFilterLayer data={data} onHide={onHide} />
      </Modal.Body>
    </>
  );
};
export default SaveFilterLayerModal;
