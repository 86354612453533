import { isBoolean } from 'lodash';
import {
  ContentType,
  LayersByTokenDocument,
  LayerType,
  useAddLayerMutation,
  useDeleteLayerMutation,
  useUpdateLayerMutation,
} from '../../../../../../generated/graphql';

const useLayerEditFormController = (data: any, onHide: any) => {
  const [addLayer] = useAddLayerMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: LayersByTokenDocument }],
  });
  const [updateLayer] = useUpdateLayerMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: LayersByTokenDocument }],
  });
  const [deleteLayer] = useDeleteLayerMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: LayersByTokenDocument }],
  });

  const onFinish = async (values: any): Promise<void> => {
    const viewSettings = { opacity: 1, zMin: 17, zMax: 23 };
    if (data?.name) {
      await updateLayer({
        variables: {
          layer: {
            ...viewSettings,
            id: data?.id,
            isAllowedToExport: isBoolean(values?.isAllowedToExport)
              ? values?.isAllowedToExport
              : data?.isAllowedToExport,
            isAllowedToImport: isBoolean(values?.isAllowedToImport)
              ? values?.isAllowedToImport
              : data?.isAllowedToImport,
            color: values?.color ? values?.color.toHexString() : data?.color || '#fff',
            groupId: data?.groupId,
            name: values.name || data?.name,
            taskLayer: isBoolean(values?.taskLayer) ? values?.taskLayer : data?.taskLayer,
            editable: isBoolean(values?.editable) ? values?.editable : data?.editable,
            updatable: isBoolean(values?.updatable) ? values?.updatable : data?.updatable,
          },
        },
      });
    } else {
      await addLayer({
        variables: {
          layer: {
            ...viewSettings,
            ...values,
            contentType: values?.contentType?.value || ContentType.Plot,
            layerType: values?.layerType?.value || LayerType.User,
            color: values?.color?.toHexString() || '#000000',
          },
        },
      });
    }
    onHide();
  };

  const handleDeleteLayer = (id: string | undefined): void => {
    if (id) deleteLayer({ variables: { id } });
    onHide();
  };

  return { onFinish, handleDeleteLayer };
};

export default useLayerEditFormController;
