import { Modal } from 'react-bootstrap';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import AllSavedFiltersList from '../../Registries/components/AllSavedFiltersList/AllSavedFiltersList';

const AllSavedFiltersLayerModal = ({ data, onHide }: { data: any; onHide: any }): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('registries.applyFilter')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <AllSavedFiltersList data={data} onHide={onHide} />
      </Modal.Body>
    </>
  );
};

export default AllSavedFiltersLayerModal;
