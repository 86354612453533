import { OksUsage } from '../../../../../../generated/graphql';

const oksUsages: { [key in OksUsage]: string } = {
  [OksUsage.AgriculturalBuilding]: 'СХ здание',
  [OksUsage.ApartmentBuilding]: 'МКД',
  [OksUsage.Catering]: 'Объект общепита',
  [OksUsage.Cottage]: 'Коттедж',
  [OksUsage.EducationalBuilding]: 'Для образовательной деятельности',
  [OksUsage.GasStation]: 'АЗС',
  [OksUsage.Hospital]: 'Больница',
  [OksUsage.HouseholdBuilding]: 'Хозпостройка',
  [OksUsage.IndustrialBuilding]: 'Промышленное здание',
  [OksUsage.LivingHouse]: 'Жилой дом',
  [OksUsage.Office]: 'Офисное здание',
  [OksUsage.Other]: 'Иное',
  [OksUsage.Pharmacy]: 'Аптека',
  [OksUsage.PublicBuilding]: 'Общественное здание',
  [OksUsage.ReligiousSite]: 'Религиозный объект',
  [OksUsage.ServiceStation]: 'СТО',
  [OksUsage.Shop]: 'Магазин',
  [OksUsage.Townhouse]: 'Таунхаус',
  [OksUsage.Undefined]: 'Не определен',
  [OksUsage.Unfinished]: 'ОНС',
};

export default oksUsages;
