import { BreachReason } from '../../../../../generated/graphql';

const selectData: Record<BreachReason, any> = {
  NO_CADASTRAL_REGISTRATION: {
    value: BreachReason.NoCadastralRegistration,
    label: 'Отсутствует постановка на кадастровый учет',
    alias: ' Нет Кад. учета',
    color: 'danger',
    hexColor: '#dc3545',
  },
  MISSING_REGISTRATION_RIGHTS: {
    value: BreachReason.MissingRegistrationRights,
    label: 'Отсутствует регистрация прав',
    alias: 'Нет Права',
    color: 'danger',
    hexColor: '#982532',
  },
  UNDEFINED: {
    value: BreachReason.Undefined,
    label: 'Не определен',
    alias: 'Не определен',
    color: 'info',
    hexColor: '#0dcaf0',
  },
  NONE: {
    value: BreachReason.None,
    label: 'Нет нарушения',
    alias: 'Нет нарушения',
    color: 'success',
    hexColor: '#198754',
  },
  NON_PURPOSE_LAND_USAGE: {
    value: BreachReason.NonPurposeLandUsage,
    label: 'Нецелевое использование земли',
    alias: 'Нецелевое',
    color: 'danger',
    hexColor: '#6e6e6e',
  },
};

export default selectData;
