import { Form } from 'react-bootstrap';
import { ReactElement } from 'react';
import BordersSelect from '../../../../../../shared/components/bordersSelect/BordersSelect';
import { BorderOutput, useGetBordersQuery } from '../../../../../../../generated/graphql';
import Loader from '../../../../../../shared/components/loader/Loader';

const AddressFilter = (props: any): ReactElement => {
  const { field, update, index } = props;
  const { data: borders, loading } = useGetBordersQuery();
  if (loading) return <Loader />;

  return (
    <div className="d-flex flex-row flex-wrap gap-1 justify-content-between align-items-end additional-text mb-2 w-100">
      <div className="flex-half-width" style={{ fontSize: '1rem' }}>
        <Form.Label className="additional-text">Название населенного пункта</Form.Label>
        <BordersSelect
          isMulti={false}
          needDefault={false}
          initValue={field.wb ? { value: field.wb, label: field.wb.name } : null}
          initOptions={borders?.bordersByToken as BorderOutput[]}
          onChange={(selected): void => {
            const changedField = field;
            changedField.wb = selected;
            update(index, changedField);
          }}
        />
      </div>
      <div className="flex-half-width">
        <Form.Label>Улица</Form.Label>
        <Form.Control
          disabled={!field?.wb}
          onChange={(event): void => {
            const changedField = field;
            changedField.street = event.target.value;
            update(index, changedField);
          }}
          value={field.street}
        />
      </div>
      <div className="flex-half-width">
        <Form.Label>Дом</Form.Label>
        <Form.Control
          disabled={!field?.wb}
          onChange={(event): void => {
            const changedField = field;
            changedField.house = event.target.value;
            update(index, changedField);
          }}
          value={field.house}
        />
      </div>
    </div>
  );
};
export default AddressFilter;
