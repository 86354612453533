import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import modalsReducer, { actions as modalsActions } from './modals/modalsSlice';
import mapReducer, { actions as mapActions } from './map/mapSlice';
import sourceReducer, { actions as sourceActions } from './map/source/sourceSlice';
import interactionsReducer, {
  actions as interactionsActions,
} from './map/interactions/interactionsSlice';
import objectFormReducer, { actions as objectFormActions } from './objectForm/objectFormSlice';
import actionsReducer, { actions as actionsActions } from './map/actions/actionsSlice';
import tasksReducer, { actions as tasksActions } from './tasks/tasksSlice';
import analyticsReducer, { actions as analyticsActions } from './analytics/analyticsSlice';
import notificationsReducer, {
  actions as notificationsActions,
} from './notifications/notificationsSlice';
import registriesReducer, { actions as registerActions } from './registers/registersSlice';
import * as modalsSelectors from './modals/modalsSelectors';
import * as mapSelectors from './map/mapSelectors';
import * as sourceSelectors from './map/source/sourceSelectors';
import * as interactionsSelectors from './map/interactions/interactionsSelectors';
import * as objectFormSelectors from './objectForm/objectFromSelectors';
import * as actionsSelectors from './map/actions/actionsSelectors';
import * as tasksSelectors from './tasks/tasksSelectors';
import * as analyticsSelectors from './analytics/analyticsSelector';
import * as notificationsSelectors from './notifications/notificationsSelectors';
import * as registrieselector from './registers/registerSelector';

const reducer = combineReducers({
  modalsState: modalsReducer,
  mapState: mapReducer,
  sourceState: sourceReducer,
  interactionsState: interactionsReducer,
  objectFormState: objectFormReducer,
  actionsState: actionsReducer,
  tasksState: tasksReducer,
  analyticsState: analyticsReducer,
  notificationsState: notificationsReducer,
  registriestate: registriesReducer,
});

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer,
  middleware: customizedMiddleware,
});

const actions = {
  ...modalsActions,
  ...mapActions,
  ...sourceActions,
  ...interactionsActions,
  ...objectFormActions,
  ...actionsActions,
  ...tasksActions,
  ...analyticsActions,
  ...notificationsActions,
  ...registerActions,
};

const selectors = {
  ...modalsSelectors,
  ...mapSelectors,
  ...sourceSelectors,
  ...interactionsSelectors,
  ...objectFormSelectors,
  ...actionsSelectors,
  ...tasksSelectors,
  ...analyticsSelectors,
  ...notificationsSelectors,
  ...registrieselector,
};

export type RootState = ReturnType<typeof reducer>;
export default store;
export { actions };
export { selectors };

export type AppDispatch = typeof store.dispatch;
