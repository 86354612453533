import { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Attribution } from 'ol/control';
import { Map } from 'ol';
import { pdfjs } from 'react-pdf';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ru_RU';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AuthProvider from './Authentication/components/AuthProvider/AuthProvider';
import PrivateRoute from './Authentication/components/PrivateRoute/PrivateRoute';
import Modals from './Modals';
import NotFoundPage from '../pages/404';
import { pagesRoutesMap } from '../pages/routes';
import './App.scss';
import '../../styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { actions } from '../../store';
import getEnvs, { envVars } from '../../environments/env';
import Loader from '../shared/components/loader/Loader';
import MainMapContext from './Map/contexts/MainMapContext';
import NotificationsContainer from '../shared/components/notifications/NotificationsContainer';
import WarningModalProvider from '../shared/components/WarningModal/WarningModalProvider';
import NavMenu from './NavMenu/components/NavMenu';
import AppSider from './NavMenu/components/AppSider';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import DBProvider from './DB/DBProvider';
import checkMobile from '../../utils/checkMobile';
import apolloGraphClient from '../../utils/ApolloGraphClient';
import { LogInData } from './Authentication/interfaces/LogInData';
import logIn from './Authentication/services/authentication-service';

const { graph } = getEnvs();

const map = new Map({ controls: [new Attribution()] });

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const client = apolloGraphClient(graph);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const auth = async (loginData: LogInData) => {
    const { data } = await logIn(loginData);
    localStorage.setItem('userData', JSON.stringify(data));
  };

  useEffect(() => {
    document.title = envVars.REACT_APP_PROJECT_NAME;
    if (envVars.REACT_APP_CREDENTIALS && !localStorage.getItem('userData')) {
      const [username, password] = envVars.REACT_APP_CREDENTIALS.split('|');
      auth({ username, password });
    }
  }, []);

  dispatch(actions.handleMobile(checkMobile()));

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          fontFamily: 'inherit',
        },
      }}
    >
      <ErrorBoundary>
        <DndProvider backend={HTML5Backend}>
          <ApolloProvider client={client}>
            <MainMapContext.Provider value={map}>
              <WarningModalProvider>
                <Suspense fallback={<Loader size="lg" className="centered" variant="radar" />}>
                  <AuthProvider>
                    <Router>
                      <div className="d-flex h-100">
                        <DBProvider>
                          <Routes>
                            <Route path="/" element={<PrivateRoute />}>
                              {pagesRoutesMap.map(
                                ({ path, isPrivate, Component }) =>
                                  isPrivate && (
                                    <Route
                                      key={path}
                                      path={path}
                                      element={
                                        <Suspense
                                          fallback={
                                            <Loader
                                              size="lg"
                                              className="centered"
                                              variant="radar"
                                            />
                                          }
                                        >
                                          <AppSider menu={<NavMenu />}>
                                            <Component />
                                          </AppSider>
                                        </Suspense>
                                      }
                                    />
                                  )
                              )}
                            </Route>
                            {pagesRoutesMap.map(
                              ({ path, isPrivate, Component }) =>
                                !isPrivate && (
                                  <Route
                                    key={path}
                                    path={path}
                                    element={
                                      <Suspense
                                        fallback={
                                          <Loader size="lg" className="centered" variant="radar" />
                                        }
                                      >
                                        <Component />
                                      </Suspense>
                                    }
                                  />
                                )
                            )}
                            <Route path="*" element={<NotFoundPage />} />
                          </Routes>
                          <Modals />
                          <NotificationsContainer />
                        </DBProvider>
                      </div>
                    </Router>
                  </AuthProvider>
                </Suspense>
              </WarningModalProvider>
            </MainMapContext.Provider>
          </ApolloProvider>
        </DndProvider>
      </ErrorBoundary>
    </ConfigProvider>
  );
};

export default App;
