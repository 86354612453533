import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useMenuItemsConfig from '../constants/useMenuItemsConfig';
import MenuComponent from './MenuComponent';
import useMenuItems from '../constants/useMenuItems';
import { MenuContext } from '../interfaces/menu-item-config';
import { getIsMobile } from '../../../../store/map/mapSelectors';
import './NavMenu.scss';

const NavMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const MENU_ITEMS_CONFIG = useMenuItemsConfig();
  const isMobile = useSelector(getIsMobile);
  const { MENU_ITEMS, PROFILE_MENU_ITEM } = useMenuItems();
  const logo = require('../../../assets/icon.png');
  const { collapse } = useContext(MenuContext);
  const handleMenuSelection = (key: string): void => {
    navigate(`/${key}`);
  };
  const [currLocation, setCurrLocation] = useState<string | undefined>(
    MENU_ITEMS_CONFIG.find((item: any) => location.pathname === item.route)?.key
  );

  useEffect(() => {
    if (
      !MENU_ITEMS.some((item: any) => location.pathname === item.path) &&
      location.pathname !== '/profile' &&
      MENU_ITEMS_CONFIG.length
    ) {
      navigate(`${MENU_ITEMS_CONFIG[0]?.route}`);
    }
  }, [location]);

  useEffect(() => {
    const currLoc = MENU_ITEMS_CONFIG.find((item: any) => location.pathname === item.route)?.key;

    if (currLoc) setCurrLocation(currLoc);
  }, [location]);

  return (
    <div
      className="d-flex flex-column "
      style={{ height: isMobile ? '80%' : '95%', width: collapse ? 80 : 200 }}
    >
      <div className="d-flex justify-content-center w-100 navImgMinHeight">
        <img src={logo} className="my-2 navImgWidth" alt="" />
      </div>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <MenuComponent
            menuItems={MENU_ITEMS}
            menuSelectionEvent={handleMenuSelection}
            defaultSelected={currLocation}
          />
        </div>
        <div>
          <div className="d-flex flex-column justify-content-end">
            <MenuComponent menuItems={PROFILE_MENU_ITEM} menuSelectionEvent={handleMenuSelection} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
