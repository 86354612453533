import React, { ReactNode } from 'react';
import { MenuItem } from '../types/menu-item';

export const getMenuItem = ({
  label,
  path,
  key,
  icon,
  type,
  children,
  onClick,
  onTitleClick,
  role,
}: {
  role: string;
  label: React.ReactNode;
  path: string;
  key: string;
  icon?: ReactNode;
  type?: string;
  children?: MenuItem[] | null;
  onClick?: () => void;
  onTitleClick?: () => void;
}): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    onClick,
    type,
    path,
    onTitleClick,
    role,
  } as MenuItem;
};

export const getProfileMenuItem = ({
  label,
  key,
  icon,
  onTitleClick,
  children,
  type,
}: {
  label: React.ReactNode;
  key?: React.Key;
  icon?: React.ReactNode;
  onTitleClick?: any;
  children?: MenuItem[] | void;
  type?: 'group';
}): MenuItem => {
  return {
    key,
    icon,
    onTitleClick,
    children,
    label,
    type,
  } as MenuItem;
};
