import { FieldType } from '../../../../../../../generated/graphql';
import preparePhone from '../../../../../Forms/helpers/preparePhone';
import { IFilters } from '../FilterTypes';

export const prettifyCadNum = (value: string) => {
  return value.replace('_', '');
};

export const onChangeFn = (
  value: string,
  kind: FieldType,
  field: IFilters,
  index: string,
  update: any
) => {
  switch (kind) {
    case FieldType.CadNum: {
      const changField = field;
      changField.value = prettifyCadNum(value);
      return update(index, changField);
    }
    case FieldType.Phone: {
      const changField = field;
      changField.value = preparePhone(value);
      return update(index, changField);
    }
    default:
      const changField = field;
      changField.value = value;
      return update(index, changField);
  }
};
