import { Props } from 'react-select';
import { memo, ReactElement, useMemo } from 'react';
import { isString } from 'lodash';
import { useTranslation } from 'react-i18next';
import { BorderOutput, Roles, useGetBordersQuery } from '../../../../generated/graphql';
import LayersNames from '../../../core/Map/shared/models/LayersNames';
import CustomSelect from '../CustomSelect/CustomSelect';

interface IBorderSelect {
  onChange: (props: BorderOutput[]) => void;
  regionsId?: (string | undefined)[] | null;
  initOptions?: BorderOutput[] | null;
  initValue?: any;
  roles?: Roles[];
  isMulti?: boolean;
  className?: string;
  isLoading?: boolean;
  selectProps?: Props;
  returnOptions?: boolean;
  isSearchable?: boolean;
  region?: string;
  value?: any;
  needDefault?: boolean;
  isDisabled?: boolean;
}

const BordersSelect = memo(
  ({
    onChange,
    regionsId,
    initOptions,
    initValue = null,
    isMulti = true,
    isSearchable = false,
    isDisabled = false,
    className = '',
    isLoading = false,
    selectProps,
    returnOptions = false,
    value,
    needDefault = true,
  }: IBorderSelect) => {
    const { data: allBorders } = useGetBordersQuery();
    const { t } = useTranslation();

    const options = useMemo(() => {
      const filterCb = (border: BorderOutput | null): boolean => {
        return (
          isString(value) ||
          !(isMulti ? value : [value])?.some((b: any) => {
            return b?.value?.id === border?.id;
          })
        );
      };

      return (
        (
          (initOptions ||
            allBorders?.bordersByToken?.filter(
              (border) => border?.parentId && regionsId?.includes(border?.parentId)
            )) as BorderOutput[]
        )
          ?.filter(filterCb)
          ?.map((border: BorderOutput | null) => ({
            value: border,
            label: border?.name || '',
          })) || []
      );
    }, [initOptions, value, allBorders, regionsId]);

    const handleChange = (selected: any): void => {
      const values = isMulti
        ? selected.map((option: { value: BorderOutput; label: string }) => option.value)
        : selected.value;
      onChange(returnOptions ? selected : values);
    };

    const defaultValue = useMemo(() => {
      if (isMulti) {
        return initValue?.map((border: BorderOutput) => ({ value: border, label: border.name }));
      }
      return initValue ?? (needDefault ? options[0] : null);
    }, [initValue, isMulti, options]);

    const emptyOptions: ReactElement = useMemo(() => {
      return <div>{t('borders.noDataOfSettlement')}</div>;
    }, []);

    return (
      <CustomSelect
        isDisabled={isDisabled}
        menuPlacement="auto"
        className={className}
        options={options}
        defaultValue={defaultValue}
        value={value}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        name={LayersNames.borders}
        isSearchable={isSearchable}
        onChange={handleChange}
        isLoading={isLoading}
        menuPortalTarget={document.body}
        placeholder={t('borders.borderSettlement')}
        noOptionsMessage={(): ReactElement => emptyOptions}
        {...selectProps}
      />
    );
  }
);

BordersSelect.displayName = 'BordersSelect';

export default BordersSelect;
