import { Form } from 'react-bootstrap';
import React, { ReactElement } from 'react';
import BordersSelect from '../../../../../../shared/components/bordersSelect/BordersSelect';
import { BorderOutput, useGetBordersQuery } from '../../../../../../../generated/graphql';
import Loader from '../../../../../../shared/components/loader/Loader';

const RegionFilter = (props: any): ReactElement => {
  const { field, update, index } = props;
  const { data: borders, loading } = useGetBordersQuery();
  if (loading) return <Loader />;

  const initValues = field?.value?.map((item: any) => {
    return { label: item.name, value: { ...item } };
  });

  return (
    <div className="d-flex flex-row flex-wrap gap-1 justify-content-between align-items-end additional-text mb-2 w-100">
      <div className="flex-half-width" style={{ fontSize: '1rem' }}>
        <Form.Label className="additional-text">Регион работ</Form.Label>
        <BordersSelect
          isMulti
          needDefault={false}
          value={initValues}
          initOptions={borders?.bordersByToken as BorderOutput[]}
          onChange={(selected): void => {
            const changedField = field;
            changedField.wb = selected;
            changedField.value = selected;
            update(index, changedField);
          }}
        />
      </div>
    </div>
  );
};

export default RegionFilter;
