import { Modal } from 'react-bootstrap';
import { ActionMeta } from 'react-select';
import { useTranslation } from 'react-i18next';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { ModalProps } from '../interfaces/ModalProps';
import BreachReasonsMap from '../../BackOffice/TaskObjectsView/models/BreachReasonsMap';
import {
  BreachOutput,
  BreachReason,
  GetHoByIdDocument,
  GetOksByIdsDocument,
  useAddObjectBreachMutation,
  useDeleteObjectBreachMutation,
} from '../../../../generated/graphql';
import CustomSelect from '../../../shared/components/CustomSelect/CustomSelect';

interface IInf {
  value: BreachReason;
  label: string;
  alias: string;
  color: string;
  hexColor: string;
  disabled?: boolean;
}

const InfSelectModal = (props: ModalProps): ReactElement => {
  const { data, onHide } = props;
  const { t } = useTranslation();
  const breachSingleValues = [BreachReasonsMap.UNDEFINED, BreachReasonsMap.NONE];
  const [inf, setInf] = useState<IInf[]>([]);
  const apollo = useApolloClient();

  const [addBreach] = useAddObjectBreachMutation();
  const [deleteBreach] = useDeleteObjectBreachMutation();

  const doAddBreach = async (value: BreachReason): Promise<void> => {
    await addBreach({
      variables: {
        reason: {
          breach: {
            objectId: data.id,
            contentType: data.contentType,
            breachReason: value,
          },
        },
      },
    });
  };

  const doDeleteBreach = async (value: BreachReason): Promise<void> => {
    await deleteBreach({
      variables: {
        dto: {
          objectId: data.id,
          contentType: data.contentType,
          breachReason: value,
        },
      },
    });
  };

  const clearInf = async (values?: IInf[]): Promise<void> => {
    // TODO: Нужно апдейтнуть метод "deleteObjectBreach" на бэкенде для полной очистки нарушений
    inf.forEach((breach) => doDeleteBreach(breach.value));
    setInf(values || []);
  };

  const changeInf = async (option: IInf): Promise<any> => {
    await clearInf([option]);
    await doAddBreach(option.value);
  };

  const onInputChange = async (newValue: any, actionMeta: ActionMeta<any>): Promise<any> => {
    if (actionMeta.action === 'select-option') {
      const option = actionMeta.option ?? newValue;
      if (!actionMeta.option) {
        return await changeInf(option);
      }
      if (breachSingleValues.includes(actionMeta.option)) {
        await changeInf(option);
        return onHide();
      }

      await doAddBreach(option.value);
      setInf([...inf, option]);
    }

    if (actionMeta.action === 'remove-value') {
      await doDeleteBreach(actionMeta.removedValue.value);
      setInf(inf.filter((breach: any) => breach.value !== actionMeta.removedValue.value));
    }

    if (actionMeta.action === 'clear') {
      actionMeta.removedValues.forEach((value) => doDeleteBreach(value.value));
      setInf([]);
    }

    return null;
  };

  // TODO: Дизейблить опции которые не могут быть в одном множестве
  const options = Object.values(BreachReasonsMap).filter((breach) => !inf.includes(breach));

  useEffect(() => {
    if (data?.breachReasonsObj)
      setInf(
        data?.breachReasonsObj.map(
          (reasonObj: BreachOutput) => BreachReasonsMap[reasonObj.breachReason]
        )
      );

    return (): void => {
      apollo.refetchQueries({ include: [GetHoByIdDocument, GetOksByIdsDocument] });
    };
  }, []);

  const breachSelect = useCallback(() => {
    return (
      <CustomSelect
        isMulti={
          !inf?.length || !breachSingleValues.filter((breach) => inf?.includes(breach)).length
        }
        isSearchable
        isClearable={!!breachSingleValues.filter((breach) => inf?.includes(breach)).length}
        placeholder={t('backOffice.modals.placeholderInf')}
        value={inf}
        onChange={onInputChange}
        options={options}
        isOptionDisabled={(option: any): boolean => option.disabled}
      />
    );
  }, [inf]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('backOffice.modals.selectInf')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{breachSelect()}</Modal.Body>
    </>
  );
};

export default InfSelectModal;
