import Select from 'react-select';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import { ConfigProvider, DatePicker } from 'antd';
import { FilterKinds } from '../FilterTypes';
import RemoveFilterButton from './RemoveFilterButton';

const SelectWithDates = (props: any): ReactElement => {
  const { index, field, filters, onRemove, name, update } = props;
  const { t } = useTranslation();
  const { control, getValues } = useFormContext();

  const defaultValues = Object.values(filters[field?.kind as FilterKinds].options).filter(
    (o: any) => field?.value?.includes(o?.value)
  );

  return (
    <div className="d-flex justify-content-between align-items-center gap-1">
      <Select
        name={`${name}[${index}].value`}
        className="w-100"
        isSearchable
        isMulti
        options={Object.values(filters[field.kind as FilterKinds].options)}
        placeholder={t('general.select')}
        defaultValue={defaultValues}
        onChange={(option): void => {
          const changeFiled = { ...field };
          changeFiled.value = option.map((o: any) => o.value);
          update(index, changeFiled);
        }}
      />
      <div
        className={field?.value?.length > 2 ? 'd-flex flex-column' : 'd-flex flex-row gap-1 w-50'}
      >
        {field.kind && (
          <ConfigProvider
            theme={{
              components: {
                DatePicker: {
                  zIndexPopupBase: 100000,
                },
              },
            }}
          >
            <Controller
              name={`${name}[${index}].dateFrom`}
              control={control}
              render={(): ReactElement => (
                <DatePicker
                  allowClear={false}
                  className="form-control"
                  format="DD.MM.YY"
                  disabledDate={(d): boolean =>
                    !d ||
                    d.isAfter(
                      dayjs(field?.dateTo).isValid()
                        ? dayjs(field?.dateTo).toDate()
                        : dayjs('today').toDate()
                    )
                  }
                  placeholder="с"
                  defaultValue={
                    field?.dateFrom && dayjs(field?.dateFrom).isValid()
                      ? dayjs(field?.dateFrom).startOf('day')
                      : undefined
                  }
                  onChange={(date): void => {
                    const changeFiled = { ...field };
                    changeFiled.dateFrom = date?.startOf('day');
                    update(index, changeFiled);
                  }}
                />
              )}
            />
            -
            <Controller
              name={`${name}[${index}].dateTo`}
              control={control}
              render={(): ReactElement => (
                <DatePicker
                  allowClear={false}
                  className="form-control"
                  format="DD.MM.YY"
                  placeholder="по"
                  disabledDate={(d): boolean =>
                    !d || d.isBefore(dayjs(field?.dateFrom)) || d.isAfter(dayjs())
                  }
                  defaultValue={
                    field?.dateTo && dayjs(field?.dateFrom).isValid()
                      ? dayjs(field?.dateTo).endOf('day')
                      : undefined
                  }
                  onChange={(date): void => {
                    const changeFiled = { ...field };
                    changeFiled.dateTo = date?.endOf('day');
                    update(index, changeFiled);
                  }}
                />
              )}
            />
          </ConfigProvider>
        )}
      </div>
      <RemoveFilterButton onClick={onRemove} />
    </div>
  );
};
export default SelectWithDates;
