import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentType } from '../../../generated/graphql';
import './styles.scss';

const countOfContentTypes = Object.keys(ContentType).length - 1;

const DataLoading = ({
  loading,
  onClose,
}: {
  loading: Record<string, { total: number; current: number }>;
  onClose: () => void;
}): ReactElement | null => {
  const { t } = useTranslation();
  const isAllLoaded = Object.values(loading).every((state) => {
    return state.current <= countOfContentTypes;
  });
  if (isAllLoaded) return null;

  return (
    <div className="position-absolute w-100 h-100 database-download-window">
      <div className="abs-centered card better-card w-50 overflow-scroll text-center download-window-wrapper">
        <p>{t('objects.loading')}</p>
        <p className="wait-message">{t('objects.waitMessage')}</p>
        {Object.entries(loading).map(([border, value]) => {
          if (value.current <= countOfContentTypes) return null;
          const percentage = ((value.total - value.current) / (value.total || 1)) * 100;
          return (
            <div
              key={border}
              className="d-flex justify-content-start align-items-center gap-1 p-2 w-100"
            >
              <div className="text-start border-title">{border}:</div>
              <div
                className="text-bg-primary rounded-2 d-flex align-items-center justify-content-center"
                style={{
                  width: percentage < 15 ? '10%' : `${percentage * 0.7}%`,
                }}
              >
                {percentage.toFixed(0)}%
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default DataLoading;
