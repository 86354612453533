import Select, { CSSObjectWithLabel, Props } from 'react-select';

const CustomSelect = (props: Props) => {
  return (
    <Select
      styles={{
        multiValue: (styles) =>
          ({
            ...styles,
            backgroundColor: '#0d6efd',
            borderRadius: 5,
            color: 'white',
          } as CSSObjectWithLabel),
        multiValueLabel: (styles) =>
          ({
            ...styles,
            color: 'white',
          } as CSSObjectWithLabel),
        multiValueRemove: (styles) =>
          ({
            ...styles,
            ':hover': {
              backgroundColor: '#0a53be',
              color: 'white',
            },
          } as CSSObjectWithLabel),
        menuPortal: (provided) => ({ ...provided, zIndex: 10000000 } as CSSObjectWithLabel),
      }}
      noOptionsMessage={() => 'Нет данных'}
      {...props}
    />
  );
};
export default CustomSelect;
