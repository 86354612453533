import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import init from './init';
import './index.scss';

const app = async (): Promise<void> => {
  const dterWebApp = document.getElementById('dter-webapp') as HTMLElement;
  const root = createRoot(dterWebApp);
  const virtualDom = await init();
  root.render(virtualDom);
};

app();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
