export default {
  translation: {
    analytics: {
      tableTab: {
        search: 'Поиск',
      },
      sections: 'Разделы',
      tableForms: 'Табличные формы',
      analyticsPart: 'Аналитическая часть',
      views: {
        tasks: 'Задачи',
        users: 'Качество работы',
        usersTasks: 'Аналитика заданий',
        usersQuality: 'Аналитика качества заполнения объектов',
        antsSalaries: 'Расчет заработных плат обходчиков',
        breachesCount: 'Аналитика типов нарушения в разрезе специалистов',
        processCount: 'Аналитика статусов обработки в разрезе специалистов',
        breachesDynamic: 'Динамика типов нарушения',
        processDynamic: 'Динамика статусов обработки',
        regions: 'Аналитика регионов',
      },
      objCard: {
        percentageOfComplete: 'Процент выполнения задачи',
        numberOfObjects: 'Количество объектов',
        zikType: 'Тип ЗИК',
        address: 'Адрес',
        owners: 'ФИО контактного лица',
        phone: 'Телефон контактного лица',
        statusDate: 'Дата статуса',
        taskName: 'Задание',
        status: 'Статус задания',
        executorName: 'ФИО исполнителя',
        multipliersValues: 'Введите множители в рублях по пунктам',
      },
      users: {
        user: 'ФИО обходчика',
        bo: 'ФИО сотрудника бэкофиса',
        breachReason: 'Тип нарушения',
        cost: 'Сумма',
      },
      headers: {
        antEfficiency: 'Качество работы обходчиков',
        antWork: 'Работа обходчиков',
      },
      selectors: {
        selectRegion: 'Выберите регион(ы)',
        selectTypesBreaches: 'Выберите типы нарушений',
        selectProcesses: 'Выберите статусы обработки',
        selectTypesObjects: 'Выберите типы объектов',
        selectBorders: 'Выберите границы работ',
        selectTaskStatuses: 'Выберите статусы задач',
        selectAllBorders: 'Выбраны все границы работ',
        selectAllBreaches: 'Выбраны все типы нарушений',
        selectAllStatuses: 'Выбраны все статусы',
        selectObjectWithStatus: 'Выделены объекты со статусом',
        selectDateRange: 'Укажите временной период',
      },
      filters: {
        tasksNotFound: 'Нет заданий по выбранным границам',
        objectsNotFound: 'Нет объектов с заданиями',
      },
      functions: {
        show: 'Показать',
        hide: 'Скрыть',
        positions: ' итоговые позиции',
      },
      noContent: {
        noContent: 'Для получения информации, ',
        selectRegion: 'выберите регион(ы)',
        selectBorders: 'выберите границы поселений',
        selectDateRange: 'выберите временной период',
      },
    },
    backOffice: {
      actions: {
        EMAIL: 'Отправить письмо',
        WHATSAPP: 'Отправить сообщение',
        formNotification: 'Сформировать уведомление',
        default: 'Выполнить действие',
      },
      fields: {
        addressEgrn: 'Адрес ЕГРН',
        addressFias: 'Адрес ФИАС',
        cad_number: 'Кадастровый номер',
        grpDate: 'Дата государственной регистрации права',
        grpNum: 'Номер государственной регистрации права',
        grpType: 'Вид государственной регистрации права',
        opoDate: 'Дата ограничения прав и обременения',
        opoNum: 'Номер ограничения прав и обременения',
        opoType: 'Вид ограничения прав и обременения',
      },
      menu: {
        edit: 'Редактировать объект',
        view: 'Просмотреть объект',
        selectInf: 'Указать нарушения',
        egrnView: 'Данные по ЕГРН',
        processStatus: 'Изменить статус обработки',
        addNotification: 'Сформировать уведомление',
        viewOnMap: 'Просмотреть на карте',
        bsHistory: 'Просмотреть историю изменений',
      },
      modals: {
        filters: 'Фильтры',
        chooseFilter: 'Выберите фильтр',
        noFilters: 'Нет фильтров',
        placeholderInf: 'Выберите нарушения',
        selectInf: 'Укажите нарушения',
        placeholderProcessStatus: 'Выберите статус обработки',
        processStatus: 'Укажите статус обработки',
        tabs: {
          breaches: 'Нарушения',
          processing: 'Статусы обработки',
          table: {
            date: 'Дата',
            user: 'Пользователь',
            newBreach: 'Присвоенное нарушение',
            newStatus: 'Присвоенный статус',
          },
        },
        maxFilters: 'Больше фильтров добавить нельзя',
      },
      headerOfTable: {
        displayHalf: '50%',
        displayAll: 'Все',
        totalObjects: 'Всего записей',
      },
      notifications: {
        successSave: 'Данные успешно сохранены',
        status: {
          CREATED: 'Сформировано',
          SENT: 'Отправлено',
        },
        caution: 'Уверены что хотите удалить уведомление',
        reform: 'Сформировать заново',
      },
    },
    borders: {
      borderSettlement: 'Выберите границ(у/ы) поселени(я/й)',
      chooseDistrict: 'Выберите район',
      noDataOfSettlement: 'Нет других границ поселений',
    },
    cadastralObject: {
      attributes: 'Фактический кадастровый участок - атрибуты объекта',
      tabs: {
        mainInfo: 'Основная и информация',
        ownerInfo: 'Информация о владельце',
        otherInfo: 'Дополнительная информация',
      },
      form: {
        name: 'Название объекта',
        fId: 'Кадастровый id',
        cadNum: 'Кадастровый номер',
        address: 'Адрес местоположения',
        addr: 'Фактический адрес',
        plotType: 'Тип объекта',
        usage: 'ВРИ фактический',
        cost: 'Кадастровая стоимость',
        area: 'Площадь',
        floor: 'Этажность',
        category: 'Категория использования',
        owners: 'ФИО владельца',
        phone: 'Номер телефона',
        comment: 'Комментарии',
        commentLabels: 'Примечания',
        photo: 'Фотографии объектов',
        documents: 'Фотографии документов',
        id: 'Идентификатор',
        status: 'Статус объекта',
        noDocReason: 'Документы',
        noDocReasonStatus: {
          ok: 'В наличии',
        },
        inf: 'Нарушения',
        processStatus: 'Статус обработки',
        notification: 'Уведомление',
        nameFil: 'Наименование филиала',
        nameSecure: 'Наименование пункта пропуска',
        sname: 'Наименование объекта',
        numRegOwnership: 'Номер регистрации права собственности',
        numRegEtcship: 'Номер регистрации иного вещного права',
        rooms: 'Помещения / квартиры',
        undefinedData: 'Не могу заполнить данные',
      },
      fileInput: {
        fields: {
          photo: 'Объект',
          document: 'Документы',
          hint: 'Нажмите чтобы добавить файлы',
          tooptip: 'Скачать файл',
          disabled: 'Невозможно прикрепить файлы',
          noFiles: 'Файлы отсутствуют',
        },
      },
      address: {
        fields: {
          name: 'Полное название',
          country: 'Страна',
          region: 'Регион',
          area: 'Область',
          city: 'Город',
          settlement: 'Нас. пункт',
          street: 'Улица',
          house: 'Дом',
          flat: 'Квартира',
          room: 'Квартира',
          block: 'Блок',
        },
        details: 'Детально',
        placeholder: 'Выберите или  введите улицу и дом',
      },
      fullName: {
        fields: {
          fullName: 'ФИО',
          surname: 'Фамилия',
          firstName: 'Имя',
          secondName: 'Отчество',
        },
        details: 'Детально',
      },
      cadNum: {
        unknown: 'Кадастровый номер неизвестен',
        hasProblems: 'Документы отсутствуют',
      },
      UCadnum: {
        placeholderUCadnum: 'Введите условный кадастровый номер',
        checkBoxPlaceholder: 'Условный кадастровый номер',
      },
    },
    dtechs: 'Dtechs',
    dter: { firstWord: 'Цифровая', lastWord: 'территория', full: 'Цифровая территория' },
    filters: {
      fields: {
        PROCESSING_STATUS: 'Статус обработки',
        BREACH_REASON: 'Нарушения',
        ADDRESS: 'Адрес',
        CAD_NUM: 'Кадастровый номер',
        PHONE: 'Телефон',
        USAGE: 'ВРИ',
        OWNER: 'ФИО владельца',
        REGION: 'Границы работ',
      },
    },
    forms: {
      errors: {
        required: 'Обязательное поле',
        commentNotFound: 'Комментарии не найдены',
        commentAnother: 'Иные комментарии',
        errorDeleteOKS: 'Не удалось удалить ОКС',
      },
      objectForm: {
        labels: {
          addLD: 'Добавить ЗУ',
          addCCO: 'Добавить ОКС',
        },
        stepperForm: {
          selectUsage: 'Выберите тип ВРИ',
          selectComment: 'Выберите примечание',
          success: 'Успешно!',
          objectUpdateSuccess: 'Объект успешно обновлен',
          status: {
            success: 'success',
          },
        },
        functions: {
          actionHomeOwnerships: 'Действия над домовладением',
        },
      },
    },
    general: {
      downloadButton: 'Выгрузить отчет',
      apply: 'Применить',
      back: 'Назад',
      badges: {
        statusAccepted: 'Статус присвоен',
        objectUpdateSuccess: 'Объект успешно обновлен',
      },
      chooseColumns: 'Выберите колонки для отображения',
      default: 'по умолчанию',
      details: 'Подробнее',
      donwloadGeoJSON: 'Выгрузить в GeoJSON',
      errors: {
        accessRestricted: 'У вас нет прав доступа к данному действию',
        connectionError: 'Ошибка соединения',
        error: 'Ошибка!',
        serverError: 'Внутренняя ошибка сервера',
      },
      formats: {
        date: 'дд.мм.гггг',
        regionFormat: 'dd.MM.yyyy',
      },
      hint: 'Подсказка',
      homeOwnersip: 'Домовладение',
      oks: 'Объект кап. стр.',
      household: 'Домохозяйство',
      instructions: 'Инструкция',
      legend: 'Легенда карты',
      moreSymbols: 'Введите 3 или более символов',
      next: 'Далее',
      noData: 'Нет данных',
      rub: 'руб.',
      submit: 'Подтвердить',
      select: 'Выберите значение',
      step: 'Шаг',
      success: 'Успешно',
      close: 'х',
      apsend: 'отсутствует',
      total: 'Итого',
      clear: 'Сбросить',
      closeWord: 'Закрыть',
      search: 'Найти',
    },
    logIn: {
      logIn: 'Войти',
      imageAlt: 'Изображение авторизации',
      form: {
        username: 'Имя пользователя',
        password: 'Пароль',
        submit: 'Отправить',
        authFailed: 'Имя пользователя или пароль некорректны',
        showPassword: 'Показать пароль',
        hidePassword: 'Скрыть пароль',
      },
    },
    logout: 'Выйти',
    modals: {
      close: 'Закрыть',
    },
    map: {
      mapKinds: {
        osm: 'OSM',
        bing: 'Bing',
      },
      mapReplace: {
        replace: 'Переместить объекты',
        replaceText: 'Переместить в новый слой:',
        placeHolderReplace: 'Выберите слой для переноса',
      },
      mapActions: {
        functions: 'Функции',
        edit: 'Редактировать',
        select: 'Выбрать',
        menu: {
          replaceTo: 'Переместить в другой слой',
          close: 'Отменить выделение',
        },
        drawKinds: {
          opacity: 'Непрозрачность',
          point: 'Точка',
          lineString: 'Линия',
          polygon: 'Полигон',
          circle: 'Круг',
        },
        clear: 'Очистить слой',
        actionsTab: {
          labels: {
            cardHeader: 'Наименования',
            showLabels: 'Показывать наименования',
            cadNum: 'Кадастровый номер',
            cost: 'Стоимость',
            address: 'Адрес',
          },
          drawFunctions: {
            cardHeader: 'Опции редактирования',
          },
        },
      },
      tabs: {
        functions: 'Действия',
        layers: 'Слои',
        objects: 'Объекты',
        tasks: 'Задания',
      },
      layersGroups: {
        BORDER_AREA: 'Вторая очередь',
        SYSTEM: 'Системные слои',
        SYSTEM_DEFAULT: 'Системные слои (по умолчанию)',
        SYSTEM_PKK: 'Системные PKK',
        USER: 'Пользовательские',
        SQUATTING: 'Самозахват',
      },
      layerControls: {
        addNewGroup: 'Добавить новую группу',
        addNewLayer: 'Добавить новый слой',
        editLayer: 'Редактировать слой',
        editLayerForm: {
          layerName: 'Название нового слоя',
          layerNameError: 'Введите название слоя!',
          layerColor: 'Цвет слоя',
          layerContentType: 'Объект',
          layerContentTypeError: 'Выберите тип объекта',
          layerContentTypePlaceholder: 'Выберите тип объектов',
          layerType: 'Тип слоя',
          layerTypePlaceholder: 'Выберите тип слоя',
          visible: 'Публичный',
          editable: 'Изменяемый',
          updatable: 'Обновляемый',
          isAllowedToExport: 'Экспортируемый',
          isAllowedToImport: 'Импортируемый',
          createNewLayer: 'Создать новый слой',
          updateLayer: 'Обновить слой',
          deleteLayer: 'Удалить слой',
          taskLayer: 'Доступен для заданий',
        },
        layerName: 'Название слоя',
        groupName: 'Название группы',
        color: 'Цвет',
        group: 'Группа',
        groupLink: 'Привязать к группе',
        editable: 'Редактируемый',
      },
    },
    nav: {
      mapPage: 'Карта',
      tasks: 'Задания',
      users: 'Пользователи',
      analytics: 'Аналитика',
      backOffice: 'Учетная часть',
      registries: 'Реестры записей',
    },
    notFound: {
      oops: 'Упс!',
      404: '404 Не Найдено',
      errorMessage: 'Извините, произошла ошибка, запрашиваемая страница не найдена!',
      link: 'Вернуться в приложение',
    },
    objects: {
      HO: 'ДХ',
      PLOT: 'ЗУ',
      OKS: 'ОКС',
      ROOM: 'Помещение',
      loading: 'Загрузка данных геометрии',
      waitMessage: 'Пожалуйста, подождите',
      updateObjectsData: 'Обновить данные объектов',
    },
    registries: {
      above: 'найти все значения больше введенного',
      aboveEquals: 'больше или равно',
      below: 'найти все значения меньше введенного',
      belowEquals: 'меньше или равно',
      equals: 'поиск конкретного значения',
      filters: 'Фильтры',
      notEquals: 'поиск всех значений кроме введенного',
      notInclude: 'результаты не включают введенное значение',
      applyFilter: 'Применить фильтр',
      saveFilterLayerModal: 'Сохранить фильтр в качестве слоя',
      savedFilterLayersModal: 'Выберите один из сохраненных фильтров',
      searchColumnPlaceholder: 'Поиск по',
      searchEquals: 'поиск совпадений',
      showAllPoints: 'Показать все точки',
      sharedUsers: 'Поделиться с пользователями',
      chooseUser: 'Выберите пользователя',
      filterName: 'Название фильтра',
      filterDescription: 'Описание фильтра',
      saveFilter: 'Сохранить фильтр',
      views: {
        registriesLabel: 'Доступные реестры',
      },
      card: {
        encumbrance: 'Обременение',
        rights: 'Правоотношения',
        allInfo: 'Общие сведения',
        square: 'Площадь обременения',
        dateIn: 'Дата с',
        dateTo: 'Дата по',
        decription: 'Содержание обременения',
        document: 'Документ-основание, тип',
        documentNumber: 'Документ-основание, номер',
        documentDate: 'Документ-основание, дата',
        formsCode: 'Тип субъекта правоотношений',
        relatCode: 'Вид правоотношения',
        docNum: 'Номер документа',
        rightDate: 'Дата регистрации права',
        stopDate: 'Дата прекращения права',
      },
    },
    smartFilter: {
      operators: {
        bn: 'не начинается',
        bw: 'начинается',
        cn: 'содержит',
        en: 'не оканчивается',
        eq: 'равно',
        ew: 'оканчивается',
        ge: 'больше или равно',
        gt: 'больше',
        le: 'меньше или равно',
        lt: 'меньше',
        nc: 'не содержит',
        ne: 'не равно',
        nn: 'присутствует',
        nu: 'отсутствует',
        AND: 'И',
        OR: 'ИЛИ',
      },
      list: {
        headerOfTable: 'Фильтры из таблицы',
        headerSmartFilter: 'Фильтры из умного фильтра',
      },
      placeholders: {
        addFilter: 'Добавить фильтр',
        selectOperator: 'Выберите оператор',
        selectValues: 'Укажите значение',
      },
    },
    errorElement: {
      errorMessage: 'Упс! Что-то пошло не так... Попробуйте обновить страницу',
    },
    tasks: {
      actions: {
        createTask: 'Создать задачу',
        newTask: 'Перейти к задаче',
        updateTask: 'Обновить задачу',
      },
      description: {
        documents: {
          ownerDescription:
            '(Когда в домовладении проживает не сам собственник, а его родственник или знакомый)',
          relativeDescription:
            '(Попросить забрать документы у родственника и предоставить копии в администрацию ОМСУ к определенному сроку)',
        },
      },
      form: {
        chooseExecutor: 'Выберите исполнителя',
        chooseAuthor: 'Выберите автора',
        chooseObjects: 'Выберите объекты',
        chosenObjects: 'Выбранные объекты',
        chooseNoDocumentReason: 'Выберите причину отсутствия документов',
        chooseStatus: 'Отфильтровать по статусу',
        chooseUser: 'Выберите пользователя',
        countObjects: 'Количество домовладений',
        dateOfComplete: 'Уточнить возможный срок завершения оформления',
        deselectAll: 'Отменить выделение',
        setType: 'Тип задания',
        setName: 'Название задания',
        setDeadLine: 'Дата завершения задания',
        setDescription: 'Описание задачи',
        setExecutor: 'Назначить исполнителя',
        setAuthor: 'Назначить автора',
        selectAll: 'Выбрать все задачи',
      },
      mainEntities: {
        status: 'Статус объекта',
        address: 'Адрес',
        authorName: 'Автор задачи',
        taskType: 'Тип задачи',
        deadLine: 'Дата завершения задачи',
        description: 'Описание',
        executorName: 'Исполнитель',
        name: 'Название задачи',
        plotIds: 'Выбранные объекты',
        chooseExecutor: 'Выберите исполнителя',
        ownerData: 'ФИО владельца / Телефон',
        executorData: 'ФИО обходчика / Телефон',
        zikType: 'Тип ЗИК',
        objCount: 'Количество ЗУ / ОКС',
        objInfCount: 'Количество нарушений ЗУ (ОКС)',
        reserve: 'Вторая очередь',
      },
      statuses: {
        UNCOMPLETED: 'Не завершено',
        NOT_STARTED: 'Не начато',
        COMPLETE: 'Завершено',
        allTaskView: 'Без статуса',
        documents: {
          selectTypeTask: {
            CHECK_OBJECTS: 'Сбор сведений о ДХ',
            CHECK_OKS: 'Сбор сведений об ОКС',
            Inventory: 'Инвентаризация',
            SociologicalSurvey: 'Проведение социологического опроса',
            PopulationCensus: 'Перепись населения',
            ClarificationOwnership: 'Уточнение права собственности',
          },
          filled: 'Заполнен',
          hasProblems:
            'Если у Вас возникли проблемы с документами при оформлении, нажмите на этот текст',
          inOrder: 'Документы в порядке',
          lost: 'Документ утерян',
          noData: 'Нет данных',
          notCompleted: 'Документ не оформлен',
          onClearance: 'Документ на оформлении',
          ownerOfDocument: {
            owner: 'Документ у собственника',
            relative: 'Документ у родственника',
          },
          preFilled: 'Частично заполнен',
        },
        errors: {
          usersError: 'Пользователи не найдены',
          typeTaskError: 'Нет доступных типов задач',
          executorError: 'Исполнители не найдены',
        },
        objects: {
          setDoneStatus: 'Завершить обход',
          cancelDoneStatus: 'Домовладение не готово к завершению',
        },
      },
    },
    users: {
      form: {
        username: 'Имя пользователя',
        password: 'Пароль',
        firstName: 'Имя',
        lastName: 'Фамилия',
        phone: 'Номер телефона',
        profile: 'Профиль пользователя',
        workBorders: 'Границы работ',
        assignedUsers: 'Связанные пользователи',
        roles: 'Роль',
        defRole: 'Менеджер',
        add: 'Создать',
        delete: 'Удалить',
        block: 'Заблокировать',
        unBlock: 'Разблокировать',
        update: 'Обновить',
        regions: 'Связанные регионы',
        defRegion: 'Регион по умолчанию',
        showPassword: 'Показать пароль',
        hidePassword: 'Скрыть пароль',
        createUser: 'Создание нового пользователя',
        updateUser: 'Обновление пользователя',
        deletedUser: 'Пользователь удален',
        createUserBut: 'Создать пользователя',
        requiredField: 'Поле обязаельно для заполнения',
        minThree: 'Должен быть минимум один символ',
        minSix: 'Должно быть минимум шесть символов',
        phoneMin: 'Телефон состоит из 11 чисел',
        disabledDelWithAssigned: 'Невозможно удалить! Имеются привязанные пользователи',
        userIsBlocked: 'Заблокирован',
        roleViews: {
          standardUser: 'Пользователь',
          backOffice: 'Бэк-офис',
          ant: 'Специалист',
          antManager: 'Менеджер',
          antOfficer: 'Управляющий',
          viewer: 'Администрация',
          back_office: 'Бэк-офис',
          ant_manager: 'Менеджер',
          admin_user: 'Администратор системы',
          ant_officer: 'Бригадир',
          developer: 'Разработчик',
          standard_user: 'Пользователь',
          registry: 'Управляющий источниками данных',
        },
        registriesView: {
          selectRegistries: 'Выберите реестры для доступа',
        },
      },
    },
  },
};
