import { BiCheckCircle } from 'react-icons/bi';
import { FiTrash, FiUserCheck, FiUserPlus, FiUserX } from 'react-icons/fi';
import { NotificationModel } from './NotificationModel';

export type NotificationType = Record<string, NotificationModel>;

const stdDelay = 3000;
const stdIcon = BiCheckCircle;
const Notifications: NotificationType = {
  updateMapObjectLayer: {
    body: 'Успешно',
    header: 'Перенос слоя',
    statusIcon: stdIcon,
    delay: stdDelay,
    status: 'success',
  },
  addTask: {
    body: 'Успешно добавлена!',
    header: 'Задача',
    statusIcon: stdIcon,
    delay: stdDelay,
    status: 'success',
  },
  updateTaskStatus: {
    body: 'Успешно изменен! Прогресс задачи изменился.',
    header: 'Объект задачи',
    statusIcon: stdIcon,
    delay: stdDelay,
    status: 'success',
  },
  registerUserV2: {
    body: 'Успешно создан!',
    header: 'Пользователь',
    statusIcon: FiUserPlus,
    delay: stdDelay,
    status: 'success',
  },
  updateUserV2: {
    body: 'Успешно обновлен!',
    header: 'Пользователь',
    statusIcon: FiUserCheck,
    delay: stdDelay,
    status: 'success',
  },
  deleteUser: {
    body: 'Успешно удален!',
    header: 'Пользователь',
    statusIcon: FiUserX,
    delay: stdDelay,
    status: 'success',
  },
  splitObjects: {
    body: 'Успешно создано!',
    header: 'Новое домовладение',
    statusIcon: stdIcon,
    delay: stdDelay,
    status: 'success',
  },
  deleteTask: {
    body: 'Успешно удалена!',
    header: 'Задача',
    statusIcon: FiTrash,
    delay: stdDelay,
    status: 'success',
  },
  mergeObjects: {
    body: 'Успешно объединены!',
    header: 'Домовладения',
    statusIcon: stdIcon,
    delay: stdDelay,
    status: 'success',
  },
  updateProcessStatus: {
    body: 'Успешно обновлен!',
    header: 'Статус обработки',
    statusIcon: stdIcon,
    delay: stdDelay,
    status: 'success',
  },
  sendNotification: {
    body: 'Успешно отправлено!',
    header: 'Уведомление',
    statusIcon: stdIcon,
    delay: stdDelay,
    status: 'success',
  },
};
export default Notifications;
