import { KeyboardEventHandler, ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { BiErrorCircle } from 'react-icons/bi';
import { makeUniqueId } from '@apollo/client/utilities';
import { useDispatch } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import { searchFilterObject } from '../SmartFilter/components/SmartFilterItem/useSmartFilterData';
import { setFilterLayer } from '../SmartFilter/services/filter-services';
import { actions } from '../../../../../store';

interface Option {
  readonly label: string;
  readonly value: string;
}

type IObject = {
  filterName: string;
  dto: searchFilterObject;
  description: string;
  // TODO: Uncomment *1
  // sharedUsers?: readonly Option[];
};

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string): { label: string; value: string } => ({
  label,
  value: label,
});

const SaveFilterLayerView = (props: any): ReactElement => {
  const { filter, onHide } = props;
  const dispatch = useDispatch();
  const { control, getValues } = useForm<IObject>({
    defaultValues: {
      filterName: 'Новый фильтр',
      dto: filter,
      description: '',
      // sharedUsers: []
    },
  });

  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [multiValue, setMultiMultiValue] = useState<readonly Option[]>([]);

  const onSubmit = (): void => {
    (async (): Promise<void> => {
      // TODO: Uncomment - shared users *1
      // append(multiValue);
      const data: AxiosResponse = await setFilterLayer(getValues());
    })();
    onHide();
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    const checkEmail = (email: string) => {
      const EMAIL_REGEXP =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      return EMAIL_REGEXP.test(email);
    };

    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (checkEmail(inputValue)) {
          setMultiMultiValue((prev) => [...prev, createOption(inputValue)]);
          setInputValue('');
          event.preventDefault();
        } else {
          dispatch(
            actions.addNotification({
              status: 'error',
              body: 'E-mail введен неверно',
              header: t('general.errors.error'),
              statusIcon: BiErrorCircle,
              delay: 5000,
              id: makeUniqueId('err-'),
            })
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <Form layout="vertical" onFinish={onSubmit} className="d-flex flex-column">
      <Form.Item label={t('registries.filterName')}>
        <Controller
          name="filterName"
          control={control}
          defaultValue=""
          render={({ field }): ReactElement => (
            <Input {...field} onSubmit={(e): void => e.preventDefault()} className="my-1" />
          )}
        />
      </Form.Item>
      <Form.Item label={t('registries.filterDescription')}>
        <Controller
          name="description"
          control={control}
          render={({ field }): ReactElement => (
            <TextArea
              {...field}
              rows={4}
              showCount
              maxLength={256}
              autoSize={{ minRows: 2, maxRows: 6 }}
              placeholder="Внесите описание фильтра (необязательно)"
            />
          )}
        />
      </Form.Item>
      {
        // TODO: Uncomment как Леня добавит функционал "Поделиться с пользователями" в метод по сохранению фильтров *1
      }
      {/* <Controller */}
      {/* name="sharedUsers" */}
      {/* control={control} */}
      {/* defaultValue={undefined} */}
      {/* render={({ field }): ReactElement => ( */}
      {/*   <Form.Item label={t('registries.sharedUsers')}> */}
      {/*     <CreatableSelect */}
      {/*       {...field} */}
      {/*       components={components} */}
      {/*       inputValue={inputValue} */}
      {/*       isMulti */}
      {/*       menuIsOpen={false} */}
      {/*       onChange={(newValue): void => setMultiMultiValue(newValue)} */}
      {/*       onInputChange={(newValue): void => setInputValue(newValue)} */}
      {/*       onKeyDown={handleKeyDown} */}
      {/*       value={multiValue} */}
      {/*       placeholder={t('registries.chooseUser')} */}
      {/*       className="my-1" */}
      {/*     /> */}
      {/*   </Form.Item> */}
      {/* )} */}
      {/* /> */}
      <Button type="primary" htmlType="submit" className="m-2 w-50 centered">
        {t('registries.saveFilter')}
      </Button>
    </Form>
  );
};
export default SaveFilterLayerView;
