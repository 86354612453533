import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import fetch from 'cross-fetch';
import { setContext } from '@apollo/client/link/context';

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('userData')
    ? JSON.parse(localStorage.getItem('userData') as string)?.jwt
    : null;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const apolloGraphClient = (schema: string) => {
  const userClient = new ApolloClient({
    link: from([authLink, new HttpLink({ uri: `${schema}`, fetch })]),
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization, Accept',
    },
    cache: new InMemoryCache(),
  });

  return userClient;
};

export default apolloGraphClient;
