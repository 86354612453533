import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskObject, TaskObjectStatus } from '../../generated/graphql';

export interface TasksState {
  objects: TaskObject[];
  newObjStatus: { objId: string; status: TaskObjectStatus } | null;
  currTaskId: string | null;
}

const initialTasksState: TasksState = {
  objects: [],
  newObjStatus: null,
  currTaskId: null,
};

const tasksSlice = createSlice({
  name: 'tasksSlice',
  initialState: initialTasksState,
  reducers: {
    handleObjects(
      draftState,
      action: PayloadAction<{ objects: TaskObject[]; taskId: string | null }>
    ) {
      draftState.objects = action.payload.objects;
      draftState.currTaskId = action.payload.taskId;
    },
    changeObjStatus(
      draftState,
      action: PayloadAction<{ objId: string; status: TaskObjectStatus }>
    ) {
      draftState.newObjStatus = action.payload;
    },
    changeTask(draftState, action: PayloadAction<string | null>) {
      draftState.currTaskId = action.payload;
    },
  },
});

export const { actions } = tasksSlice;
export default tasksSlice.reducer;
