const LayersNames = {
  tasksFeatures: 'tasks-features',
  OSM: 'OSM',
  borders: 'borders',
  regions: 'regions',
  OFP: 'ofpName',
  ofp: 'ofp',
  layout: 'regions-layout',
  vectorLayers: 'vectorLayer',
  ofpClip: 'ofp-clip',
  featuresLayer: 'featuresLayer',
  debug: 'debug',
  objects: 'objects',
  draggableBox: 'draggableBox',
};
export default LayersNames;
