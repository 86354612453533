/**
 * Get initial path as string.
 *
 * Returns callback.
 *
 * Callback takes array of parameters (string[]) and return joined string with '.' separator.
 * @param initPath - string | undefined
 */
const useTranslate = (initPath?: string) => {
  const translate = (...parts: string[]): string => {
    return initPath ? [initPath, parts].join('.') : parts.join('.');
  };

  return translate;
};
export default useTranslate;
