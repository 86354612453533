import { Dispatch, SetStateAction, useContext } from 'react';
import GeoData from './GeoData';
import useNeedToUpdate from './hooks/useNeedToUpdate';
import DBContext from '../ui/core/DB/DBContext';
import { db } from './db';

const useLoadDB = (
  showLoader: Dispatch<SetStateAction<boolean>>,
  setLoading: Dispatch<SetStateAction<Record<string, { total: number; current: number }>>>
): { resetDB: () => void; updateDB: (borders: any) => void } => {
  const { canUseDb } = useContext(DBContext);
  const needToUpdate = useNeedToUpdate();

  const updateDB = (borders: any): void => {
    if (canUseDb) {
      borders.forEach(async (border: any) => {
        const updatable = await needToUpdate();
        if (updatable) {
          showLoader(true);
          const geoData = new GeoData({ borderId: Number(border?.id) });
          geoData.loading.subscribe((value: { page: number; borderId: number; total: number }) => {
            setLoading((prevState) => ({
              ...prevState,
              [border?.name as string]: { total: value.total, current: value.page },
            }));
          });
          await geoData.init();
          await geoData.getPageData(geoData.totalPagesPlot, geoData.totalPagesOks);
        }
      });
    }
  };

  const resetDB = (): void => {
    if (canUseDb) {
      db.delete();
      db.open();
    }
  };

  return { updateDB, resetDB };
};
export default useLoadDB;
