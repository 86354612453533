import Sider from 'antd/es/layout/Sider';
import React from 'react';
import { CgArrowLeftO, CgArrowRightO } from 'react-icons/cg';
import { Button } from 'react-bootstrap';
// @ts-ignore
import styles from './AppSider.module.scss';
import { MenuContext } from '../interfaces/menu-item-config';
import { useMedia } from '../../../../utils/useMedia';

interface AppSiderProps {
  readonly children: React.ReactNode;
  readonly menu: React.ReactNode;
}

const AppSider: React.FC<AppSiderProps> = ({ children, menu }) => {
  const [collapsed, setCollapsed] = React.useState(true);

  const isMobile = useMedia('(max-width: 425px)');

  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <MenuContext.Provider value={{ collapse: collapsed || isMobile }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed || isMobile}
        onCollapse={(value): void => setCollapsed(value)}
        width={200}
        className={styles.sider}
      >
        <Button
          className="w-100 border-0"
          id="collapse-btn"
          style={{
            borderRadius: 0,
            backgroundColor: '#fff',
            color: '#0d6efd',
            display: isMobile ? 'none' : 'unset',
          }}
          onClick={handleCollapse}
        >
          {collapsed ? <CgArrowRightO /> : <CgArrowLeftO />}
        </Button>
        {menu}
      </Sider>
      {children}
    </MenuContext.Provider>
  );
};

export default AppSider;
