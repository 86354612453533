import {
  AdministrationProcessStatusObject,
  CommonProcessStatusObject,
} from '../../core/BackOffice/TaskObjectsView/models/CommonProcessStatusObject';

const getProcessStatusObject = (role?: string): any => {
  switch (role) {
    case 'Administration':
      return AdministrationProcessStatusObject;
    case 'Admin':
      return { ...AdministrationProcessStatusObject, ...CommonProcessStatusObject };
    default:
      return CommonProcessStatusObject;
  }
};

export default getProcessStatusObject;
