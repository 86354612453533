import { FieldType } from '../../../../../../generated/graphql';

export interface FilterProps {
  onChange: (obj: any) => void;
  onAddressChange: (obj: any) => void;
  onRegionChange: (obj: any) => void;
  onFieldFiltersChange: (obj: any) => void;
  onSubmitFn: any;
  data: any;
}

export enum FilterKinds {
  PROCESSING_STATUS = 'PROCESSING_STATUS',
  BREACH_REASON = 'BREACH_REASON',
  ADDRESS = 'ADDRESS',
  REGION = 'REGION',
}

export interface IFilters {
  value: string;
  kind: string;
  dateFrom?: Date | null;
  dateTo?: Date | null;
  emptyField?: boolean | null;
}

export interface IForm {
  [key: string]: any;
  filters: IFilters[];
}

export const FiltersKinds = [...Object.values(FilterKinds), ...Object.values(FieldType)] as const;

// export type Kinds = typeof FiltersKinds[number];

export type Kinds = FilterKinds | FieldType;

export type FiltersTypes = 'selectWithDate' | 'address' | 'region' | 'input' | 'usage';
