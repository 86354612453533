import Dexie, { Table } from 'dexie';
import { dbName } from './constants';
import { GeoDbOptions } from './types';
import { GeometryResponseDto } from '../generated/graphql';

export class GeoDb extends Dexie {
  geo!: Table<GeometryResponseDto>;

  constructor({ dbVersion }: GeoDbOptions) {
    super(dbName);

    this.version(dbVersion).stores({
      geo: 'id, [workBorderId+contentType]',
    });
  }
}

export const db = new GeoDb({ dbVersion: 1 });
