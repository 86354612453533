import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from '../routes';
import './index.scss';

const NotFoundPage = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="container d-flex align-items-center justify-content-center m-5">
      <div className="row">
        <div className="col-md-12">
          <div className="error-template">
            <h1>{t('notFound.oops')}</h1>
            <h2>{t('notFound.404')}</h2>
            <div className="error-details">{t('notFound.errorMessage')}</div>
            <div className="error-actions">
              <Link to={routes.mapPagePath()} className="btn btn-primary btn-lg m-3">
                {t('notFound.link')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
