import { RootState } from '../../index';
import { ActionsState } from './actionsSlice';
import { HintField } from '../../../generated/graphql';

const getActionsState = (state: RootState): ActionsState => state.actionsState;

const getLabelsVisibility = (state: RootState): boolean => state.actionsState.showLabels;

const getLabelsField = (state: RootState): HintField => state.actionsState.labelsField;

export { getActionsState, getLabelsVisibility, getLabelsField };
