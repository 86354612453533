import main from './environments';
import local from './local';

declare global {
  interface Window {
    env: any;
  }
}

type EnvType = {
  REACT_APP_PROJECT_NAME: string;
  REACT_APP_CREDENTIALS: string;
};

export const envVars: EnvType = { ...process.env, ...window.env };

const getEnvs = () =>
  ({
    production: main,
    development: local,
  }[process.env.NODE_ENV || 'production'] as any);

export default getEnvs;
