import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ContentType,
  GroupDto,
  InTaskStatusInput,
  Layer,
  LayerType,
} from '../../../generated/graphql';

export interface SourceState {
  layersSettings: Layer[];
  groupSettings: GroupDto[];
  activeLayerId: string | null;
  visibleLayers: string[];
  newLayout: Layer | GroupDto | null;
  newLayerId: string;
  refreshObjectsHandler: boolean;
  layerStatus: InTaskStatusInput | null | boolean;
}

const initialSourceState: SourceState = {
  layersSettings: [],
  groupSettings: [],
  activeLayerId: null,
  visibleLayers: [],
  newLayout: null,
  newLayerId: '',
  refreshObjectsHandler: true,
  layerStatus: InTaskStatusInput.All,
};

const sourceSlice = createSlice({
  name: 'sourceInfo',
  initialState: initialSourceState,
  reducers: {
    addLayerSetting(draftState, action: PayloadAction<Layer>) {
      draftState.layersSettings.push(action.payload);
      draftState.newLayout = action.payload;
      if (action.payload) {
        draftState.activeLayerId = action.payload.id;
      }
    },
    addGroupSetting(draftState, action: PayloadAction<GroupDto>) {
      draftState.groupSettings.push(action.payload);
      draftState.newLayout = action.payload;
    },
    changeSourceActivity(draftState, action: PayloadAction<string | null>) {
      draftState.activeLayerId = action.payload;
    },
    changeLayerVisibility(draftState, action: PayloadAction<string[]>) {
      draftState.visibleLayers = action.payload;
    },
    getAllLayouts(draftState, action: PayloadAction<{ allLayers: any }>) {
      draftState.layersSettings = action.payload.allLayers.length
        ? action.payload.allLayers
        : draftState.layersSettings;
      draftState.activeLayerId =
        action.payload.allLayers.find(
          (layer: Layer) =>
            layer.contentType === ContentType.Plot && layer.layerType === LayerType.SystemDefault
        )?.id || null;
    },
    handleNewLayerId(draftState, action: PayloadAction<string>) {
      draftState.newLayerId = action.payload;
    },
    handleObjectsRefresh(draftState, action: PayloadAction<boolean>) {
      draftState.refreshObjectsHandler = action.payload;
    },
    changeLayerStatus(draftState, action: PayloadAction<InTaskStatusInput | null | boolean>) {
      draftState.layerStatus = action.payload;
    },
  },
});

export const { actions } = sourceSlice;
export default sourceSlice.reducer;
