import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { View } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { MenuStates } from '../../ui/shared/models/MenuStates';
import initialView from '../../ui/core/Map/shared/presets/initialView';
import { MapCondition } from '../../ui/core/Map/shared/models/MapCondition';

export interface MapState {
  tileLayer: any;
  view: View;
  featuresFlag: boolean;
  currZoom: number;
  isMobile: boolean;
  showDebug: boolean;
  geoPosition: Coordinate | null;
  menuState: MenuStates;
  mapCondition: MapCondition;
}

export const initialMapState: MapState = {
  tileLayer: 'osm',
  view: new View(initialView),
  featuresFlag: true,
  currZoom: 13,
  isMobile: false,
  showDebug: false,
  geoPosition: null,
  menuState: 'closed',
  mapCondition: 'idle',
};

const mapSlice = createSlice({
  name: 'mapInfo',
  initialState: initialMapState,
  reducers: {
    addMapView(draftState, action: PayloadAction<View>) {
      draftState.view = action.payload;
    },
    changeTileLayer(draftState, action: PayloadAction<any>) {
      draftState.tileLayer = action.payload;
    },
    changeFeaturesFlag(draftState) {
      draftState.featuresFlag = !draftState.featuresFlag;
    },
    changeZoom(draftState, action: PayloadAction<number>) {
      draftState.currZoom = action.payload;
    },
    handleMobile(draftState, action: PayloadAction<boolean>) {
      draftState.isMobile = action.payload;
    },
    handleShowDebug(draftState) {
      draftState.showDebug = !draftState.showDebug;
    },
    changeGeoPosition(draftState, action: PayloadAction<Coordinate>) {
      draftState.geoPosition = action.payload;
    },
    changeMenuState(draftState, action: PayloadAction<MenuStates>) {
      draftState.menuState = action.payload;
    },
    resetMap(draftState) {
      draftState.view = new View(initialView);
    },
    handleMapState(draftState, action: PayloadAction<MapCondition>) {
      draftState.mapCondition = action.payload;
    },
  },
});

export const { actions } = mapSlice;
export default mapSlice.reducer;
