import { RootState } from '../index';
import { AnalyticsState } from './analyticsSlice';
import { Views } from '../../ui/core/Analytics/Views/models/Views';
import { TableViews } from '../../ui/core/Analytics/Views/models/TableViews';
import { AnalyticsViews } from '../../ui/core/Analytics/Views/models/AnalyticsViews';

const getAnalyticsState = (state: RootState): AnalyticsState => state.analyticsState;

const getActiveViews = (state: RootState): Views => state.analyticsState.activeViews;

const getTableView = (state: RootState): TableViews => state.analyticsState.tableView;

const getAnalyticsView = (state: RootState): AnalyticsViews => state.analyticsState.analyticsView;

const getFilters =
  (filterName: AnalyticsViews | TableViews) =>
  (state: RootState): any => {
    const { filters } = state.analyticsState;
    if (filters.hasOwnProperty(filterName)) return filters[filterName];
    return null;
  };

const getSelectedObject = (state: RootState): any => state.analyticsState.selectedObject;

export {
  getAnalyticsState,
  getActiveViews,
  getTableView,
  getAnalyticsView,
  getSelectedObject,
  getFilters,
};
