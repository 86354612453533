import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormState, FormTabs } from '../../ui/shared/models/FormStates';

export interface ObjectFormState {
  activeTab: FormTabs;
  formState: FormState;
  chosenForm: string | null;
}

const initialObjectFormState: ObjectFormState = {
  activeTab: 'form',
  formState: 'closed',
  chosenForm: null,
};

const objectFormSlice = createSlice({
  name: 'objectFormSlice',
  initialState: initialObjectFormState,
  reducers: {
    changeActiveTab(draftState, action: PayloadAction<FormTabs>) {
      draftState.activeTab = action.payload;
    },
    handleFormState(draftState, action: PayloadAction<FormState>) {
      draftState.formState = action.payload;
    },
    chooseForm(draftState, action: PayloadAction<string | null>) {
      draftState.chosenForm = action.payload;
    },
  },
});
export const { actions } = objectFormSlice;
export default objectFormSlice.reducer;
