import { createPortal } from 'react-dom';
import WarningModalView from './WarningModalView';
import usePortal from '../../../hooks/usePortal';
import './WarningModal.scss';

const WarningModal = () => {
  const { element, removePortal } = usePortal('warning-modal-container');
  return createPortal(<WarningModalView onClose={removePortal} />, element);
};
export default WarningModal;
