import { ReactElement } from 'react';
import { Button, Checkbox, ColorPicker, Form, Input } from 'antd';
import './AddLayerForm.module.scss';
import Select, { CSSObjectWithLabel } from 'react-select';
import { useTranslation } from 'react-i18next';
import { alignPropType, Color } from 'react-bootstrap/types';
import { isBoolean } from 'lodash';
import { useSelector } from 'react-redux';
import { ContentType, LayerType } from '../../../../../../generated/graphql';
import useTranslate from '../../../../../hooks/useTranslate';
import useRoles from '../../../../../hooks/useRoles';
import { getLayerSettings } from '../../../../../../store/map/source/sourceSelectors';
import useLayerEditFormController from './useLayerEditFormController';

type FieldType = {
  name: string;
  color?: Color | string;
  contentType?: ContentType;
  layerType?: LayerType;
  visible: boolean;
  editable: boolean;
  updatable: boolean;
  taskLayer: boolean;
  isAllowedToExport: boolean;
  isAllowedToImport: boolean;
};

const LayerEditForm = ({ data, onHide }: { data: any; onHide: any }): ReactElement => {
  const { t } = useTranslation();
  const trans = useTranslate('map.layerControls.editLayerForm');
  const permissions = useRoles();
  const { onFinish, handleDeleteLayer } = useLayerEditFormController(data, onHide);
  const getLayerInfo = useSelector(getLayerSettings).find((layer) => layer.name === data?.name);

  const layerTypesOptions = Object.values(LayerType)
    .filter((layer) => layer !== LayerType.BorderArea)
    .map((layer: string) => ({
      value: layer,
      label: t(`map.layersGroups.${layer}`),
    }));

  return (
    <Form onFinish={onFinish} initialValues={{ remember: true }} autoComplete="off">
      <Form.Item<FieldType>
        label={t(trans('layerName'))}
        name="name"
        rules={[{ required: !data?.name, message: t(trans('layerNameError')) }]}
      >
        <Input defaultValue={data?.name || ''} />
      </Form.Item>
      <div className="d-flex flex-column gap-4 flex-lg-row justify-content-xl-between align-items-center">
        <Form.Item<FieldType> label={t(trans('layerColor'))} name="color">
          <ColorPicker
            format="hex"
            defaultValue={data?.color || '#000000'}
            value={data?.color || '#000000'}
          />
        </Form.Item>
        {!data?.contentType && (
          <Form.Item<FieldType>
            label={t(trans('layerContentType'))}
            name="contentType"
            // rules={[{ required: true, message: t(trans('layerContentTypeError')) }]}
          >
            <Select
              styles={{
                container: (provided) =>
                  ({
                    ...provided,
                    width: 235,
                  } as CSSObjectWithLabel),
              }}
              defaultValue={{
                value: ContentType.Plot,
                label: t('objects.PLOT'),
              }}
              isClearable
              options={[
                { value: ContentType.Plot, label: t('objects.PLOT') as string },
                { value: ContentType.Oks, label: t('objects.OKS') as string },
              ]}
              placeholder={t(trans('layerContentTypePlaceholder'))}
            />
          </Form.Item>
        )}
        {!data?.layerType && (
          <Form.Item<FieldType> label={t(trans('layerType'))} name="layerType">
            <Select
              styles={{
                container: (provided) =>
                  ({
                    ...provided,
                    width: 200,
                  } as CSSObjectWithLabel),
              }}
              isClearable
              // TODO: delete 154-155 after approve from backend
              isDisabled
              defaultValue={{
                value: LayerType.User as string,
                label: t(`map.layersGroups.${LayerType.User}`),
              }}
              options={layerTypesOptions}
              placeholder={t(trans('layerTypePlaceholder'))}
            />
          </Form.Item>
        )}
      </div>
      <div className="d-flex flex-row justify-content-around">
        <Form.Item<FieldType>
          name="editable"
          valuePropName="checked"
          wrapperCol={{ offset: 2, span: 10 }}
        >
          <Checkbox defaultChecked={data?.editable || false}>{t(trans('editable'))}</Checkbox>
        </Form.Item>
        <Form.Item<FieldType>
          name="updatable"
          valuePropName="checked"
          wrapperCol={{ offset: 2, span: 10 }}
        >
          <Checkbox defaultChecked={data?.updatable || false}>{t(trans('updatable'))}</Checkbox>
        </Form.Item>
        <Form.Item<FieldType>
          name="isAllowedToExport"
          valuePropName="checked"
          wrapperCol={{ offset: 2, span: 10 }}
        >
          <Checkbox
            defaultChecked={isBoolean(data?.isAllowedToExport) ? data?.isAllowedToExport : false}
          >
            {t(trans('isAllowedToExport'))}
          </Checkbox>
        </Form.Item>
        <Form.Item<FieldType>
          name="isAllowedToImport"
          valuePropName="checked"
          wrapperCol={{ offset: 2, span: 10 }}
        >
          <Checkbox
            defaultChecked={isBoolean(data?.isAllowedToImport) ? data?.isAllowedToImport : false}
          >
            {t(trans('isAllowedToImport'))}
          </Checkbox>
        </Form.Item>
        <Form.Item<FieldType>
          name="taskLayer"
          valuePropName="checked"
          wrapperCol={{ offset: 2, span: 10 }}
        >
          <Checkbox defaultChecked={isBoolean(data?.taskLayer) ? data?.taskLayer : false}>
            {t(trans('taskLayer'))}
          </Checkbox>
        </Form.Item>
      </div>
      <div className="d-flex justify-content-center gap-3">
        <Button className="justify-content-center" type="primary" htmlType="submit">
          {data.name ? t(trans('updateLayer')) : t(trans('createNewLayer'))}
        </Button>
        {permissions.isADMIN_USER(false) &&
          !getLayerInfo?.count &&
          getLayerInfo?.layerType === LayerType.User && ( // TODO: Удалить проверку после того как бэк разрешит удалять не только пользовательские слои
            <Button
              className="justify-content-center"
              type="primary"
              onClick={(): void => handleDeleteLayer(getLayerInfo?.id)}
              danger
            >
              {t(trans('deleteLayer'))}
            </Button>
          )}
      </div>
    </Form>
  );
};

export default LayerEditForm;
